import React from 'react';
import { Link } from 'react-router-dom';
import { RiDeleteBin5Line } from 'react-icons/ri';

import { TableHead, Tag } from '../elements';

const tableHeadData = [
  { id: 1, name: 'S.no' },
  { id: 2, name: 'Customer' },
  { id: 3, name: 'Email Address' },
  { id: 5, name: 'Status' },
  { id: 6, name: 'View' },
  { id: 9, name: '' },
];

const CustomersTable = ({ data, handleRemove }) => {
  if (!data) {
    return null;
  }

  return (
    <div className="table-container">
      <table className="table is-fullwidth">
        <TableHead tableHeadData={tableHeadData} />
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.accountsEmail}</td>
              <td>
                <Tag status={item.status} />
              </td>
              <td>
                <Link to={`/customer/${item.id}`} className="button is-primary">
                  <span className="icon is-small">
                    <i className="fas fa-eye" />
                  </span>
                </Link>
              </td>
              <td>
                <button
                  className="button"
                  type="submit"
                  onClick={() =>
                    handleRemove({
                      id: item.id,
                      name: item.name,
                      status: 'archived',
                    })
                  }>
                  <RiDeleteBin5Line />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomersTable;
