import React from 'react';
import styled from 'styled-components';

const SelectInput = styled.div`
  width: ${(props) => (props.halfWidth ? '50%' : '100%')};
  select {
    padding: 0.45rem 0.9rem;
    border: 1px solid ${(props) => props.theme.borderColor};
    background-color: ${(props) => props.theme.secondaryColor};
    ::placeholder {
      color: ${(props) => props.theme.placeholderColor};
    }
  }
`;

const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizeSmall} !important;
`;

const Select = ({
  placeholder,
  options,
  errors,
  label,
  halfWidth,
  disabled,
  ...otherProps
}) => (
  <SelectInput className="field" halfWidth={halfWidth}>
    {label && (
      <Label className="label has-text-weight-semibold has-text-black mb-2">
        {label}
      </Label>
    )}
    <div className="control">
      <div className="select is-fullwidth  is-expanded is-radiusless">
        <select className="is-radiusless" {...otherProps} disabled={disabled}>
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((option) => (
            <option key={option.value || option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
    {errors && <p className="help is-danger">{errors}</p>}
  </SelectInput>
);

export default Select;
