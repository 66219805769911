import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';

import { useQuery, useMutation } from '@apollo/client';
import Layout from '../../components/Layout';
import DashboardMenu from '../../components/global/DashboardMenu';
import Seo from '../../components/Seo';
import { Loading, Heading } from '../../components/elements';
import { JobForm, JobSurveyForm } from '../../components/jobs';
import { isOnline } from '../../utils/helpers';

const Spacing = styled.hr`
  margin: 3rem 0;
  border: 1px solid ${(props) => props.theme.mainBrandColor};
`;

const jobQuery = gql`
  query job($id: ID!) {
    job(id: $id) {
      id
      startDate
      dueDate
      dateSigned
      code
      urgencyLevel
      riskAssessmentNotes
      worksCompleted
      files {
        afterPicture
        beforePicture
        historyFile
        signature
      }
      surveys {
        mensToComplete
        hoursToComplete
        details
        surveyPicture
        outOfHours
      }
      location {
        name
        addressLine1
        addressLine2
        city
        state
        postcode
        country
        formattedAddress
        location {
          coordinates
        }
      }
      riskAssessments {
        title
        value
      }
      customer {
        id
        name
      }
      contractor {
        id
        profile {
          fullName
        }
      }
      customerReference
      startDate
      status
      internalNotes
      signedBy
      description
      customerNotes
      locationInBuilding
      hasRevisit
      revisitNotes
    }
  }
`;

const updateJobMutation = gql`
  mutation updateJob($id: ID!, $input: JobInput!) {
    updateJob(id: $id, input: $input) {
      id
    }
  }
`;

const sendJobEmailMutation = gql`
  mutation sendJobEmail($id: ID!, $mailType: mailType!) {
    sendJobEmail(id: $id, mailType: $mailType) {
      success
    }
  }
`;
const sendJobSmsMutation = gql`
  mutation sendJobEmail($id: ID!) {
    sendJobSms(id: $id) {
      success
    }
  }
`;
const EditJob = ({ match, history }) => {
  const { id } = match.params;
  const { data, loading } = useQuery(jobQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });
  const [executeMutation] = useMutation(updateJobMutation);
  const [executeSendMail, res] = useMutation(sendJobEmailMutation);
  const [executeSendSms] = useMutation(sendJobSmsMutation);

  const jobData = data && data.job ? data.job : {};


  const swalAlert = (resp, title) => {
    if (resp.data) {
      Swal.fire({
        icon: 'success',
        title,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleSubmit = async (formData) => {
    // eslint-disable-next-line no-param-reassign
    delete formData.mailType;
    // eslint-disable-next-line no-param-reassign
    delete formData.smsType;

    if (isOnline()) {
      await executeMutation({
        variables: {
          id,
          input: formData,
        },
      });
    } else {
      executeMutation({
        variables: {
          id,
          input: formData,
        },
      });
    }
    swalAlert('Success', 'Job updated successfully');
    history.goBack();
  };

  const handleSendMail = async (mailType) => {

    const resp = await executeSendMail({
      variables: {
        id: jobData.id,
        mailType,
      },
    });
    swalAlert(resp, 'Mail sent successfully');
  };
  const handleSendSms = async () => {
    const resp = await executeSendSms({
      variables: {
        id: jobData.id
      },
    });
    swalAlert(resp, 'SMS sent successfully');
  };

  return (
    <Layout>
      <Seo title="Edit Job" description="Edit job page information." />
      <DashboardMenu heading="Edit Job">
        {loading && <Loading />}
        {!loading && (
          <>
            <JobForm
              enableReinitialize
              hasMailButtons
              initialValues={{
                ...jobData,
                customer: {
                  label: jobData.customer.name,
                  ...jobData.customer,
                },
                contractor: {
                  label: jobData.contractor.profile.fullName,
                  ...jobData.contractor,
                },
                site: {
                  label: jobData.location.name,
                  ...jobData.location,
                },
              }}
              loading={res.loading}
              handleSendMail={handleSendMail}
              handleSendSms={handleSendSms}
              onSubmit={(val) => {
                handleSubmit({
                  status: jobData.status,
                  ...val,
                });
              }}
            />
            <Spacing />
            <Heading>Contractor Data</Heading>
            <JobSurveyForm
              enableReinitialize
              initialValues={jobData}
              onSubmit={(val) =>
                handleSubmit({
                  startDate: jobData.startDate,
                  dueDate: jobData.dueDate,
                  customer: jobData.customer.id,
                  contractor: jobData.contractor.id,
                  ...val,
                })
              }
            />
          </>
        )}
      </DashboardMenu>
    </Layout>
  );
};

export default EditJob;
