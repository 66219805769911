import React from 'react';
import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import Seo from '../components/Seo';
import JobPdf from '../components/pdf/JobPdf';
import { Loading } from '../components/elements';

import Logo from '../assets/images/logo-blue.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  .image {
    margin-bottom: 6rem;
  }
`;

const jobQuery = gql`
  query job($id: ID!) {
    job(id: $id) {
      id
      startDate
      dueDate
      dateSigned
      code
      urgencyLevel
      riskAssessmentNotes
      files {
        afterPicture
        beforePicture
        historyFile
        signature
      }
      location {
        name
      }
      riskAssessments {
        title
        value
      }
      customer {
        id
        name
      }
      contractor {
        id
        profile {
          fullName
        }
      }
      customerReference
      startDate
      status
      internalNotes
      signedBy
      description
      locationInBuilding
      worksCompleted
      hasRevisit
      revisitNotes
      surveys {
        mensToComplete
        hoursToComplete
        details
        surveyPicture
        outOfHours
      }
    }
  }
`;

const JobPdfView = ({ match }) => {
  const { id } = match.params;
  const { data, loading } = useQuery(jobQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  const jobData = data && data.job ? data.job : {};

  return (
    <Container>
      <Seo
        title="Job completed pdf"
        description="View job completed information"
      />
      <figure className="image ">
        <img alt="logo" src={Logo} />
      </figure>
      {(loading || !data) && (
        <div className="has-text-centered">
          <h1 className="title is-1 has-text-info">Please wait...</h1>
          <Loading type="spin" />
        </div>
      )}

      {!loading && jobData && <JobPdf jobData={jobData} />}
    </Container>
  );
};

export default JobPdfView;
