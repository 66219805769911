import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { CustomersTable } from '../../components/customers';
import DashboardMenu from '../../components/global/DashboardMenu';
import { Loading, EmptyState } from '../../components/elements';
import useSearchFilter from '../../hooks/useSearchFilter';

const allCustomersQuery = gql`
  query allCustomers($limit: Int) {
    allCustomers(limit: $limit) {
      id
      name

      jobsEmail
      status
      accountsEmail
      locations {
        number
      }
    }
  }
`;

const updateCustomerMutation = gql`
  mutation updateCustomer($id: ID!, $input: CustomerInput!) {
    updateCustomer(id: $id, input: $input) {
      id
    }
  }
`;

const Customers = ({ history }) => {
  const [limit, setLimit] = useState('ALL');

  const [filteredData, { onSearch, setAllData }] = useSearchFilter([
    'name',
    'accountsEmail',
    'locations.number',
    'locations.address.addressLine1',
  ]);

  const { data, error, loading, refetch } = useQuery(allCustomersQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: limit === 'ALL' ? 0 : parseInt(limit, 10),
    },
  });

  const [executeMutation] = useMutation(updateCustomerMutation);

  useEffect(() => {
    if (error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
  }, [error]);

  useEffect(() => {
    if (data && data.allCustomers) {
      setAllData(data.allCustomers);
    }
  }, [data]);

  const handleRemoveCustomer = async (formData) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to delete ${formData.name} customer`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4896FC',
      cancelButtonColor: '#e11842',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await executeMutation({
          variables: {
            id: formData.id,
            input: {
              name: formData.name,
              status: 'archived',
            },
          },
        });
        if (resp) {
          refetch();
          Swal.fire('Deleted!', ' ', 'success');
        } else {
          history.push('');
        }
      }
    });
  };

  return (
    <Layout>
      <Seo title="Customers" description="View all customers" />
      <DashboardMenu
        hasSearchMenu
        heading="Customer"
        onChange={onSearch}
        onSelect={(e) => setLimit(e.target.value)}
        selectValue={limit}>
        {loading && !data && <Loading />}
        <div className="has-text-centered mb-5">
          {filteredData.length === 0 && !loading && <EmptyState />}
        </div>
        {filteredData && filteredData.length > 0 && (
          <CustomersTable
            data={filteredData}
            handleRemove={handleRemoveCustomer}
          />
        )}
      </DashboardMenu>
    </Layout>
  );
};

export default Customers;
