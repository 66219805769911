import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Loading } from '../../components/elements';
import DashboardMenu from '../../components/global/DashboardMenu';
import { CustomerForm } from '../../components/customers';

const userQuery = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      name
      accountsEmail
      status
      locations {
        id
        internalNotes
        name
        number
        address {
          addressLine1
          addressLine2
          city
          state
          postcode
          country
          location {
            coordinates
          }
          formattedAddress
        }
      }
    }
  }
`;

const updateCustomerMutation = gql`
  mutation updateCustomer($id: ID!, $input: CustomerInput!) {
    updateCustomer(id: $id, input: $input) {
      id
    }
  }
`;

const EditCustomer = ({ match, history }) => {
  const { id } = match.params;
  const { data, loading, error } = useQuery(userQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });
  const [executeMutation, res] = useMutation(updateCustomerMutation);

  const customerData = data && data.customer ? data.customer : {};

  useEffect(() => {
    if (res.error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.error.message,
      });
    if (error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
  }, [res.error, error]);

  const handleSubmit = async (formData) => {
    const locations = formData.locations.map((item) => {
      const address = {
        addressLine1: item.address.addressLine1,
        addressLine2: item.address.addressLine2,
        city: item.address.city,
        state: item.address.state,
        postcode: item.address.postcode,
        country: item.address.country,
        formattedAddress: item.address.formattedAddress,
        location: {
          coordinates: item.address.location.coordinates,
        },
      };

      const location = {
        name: item.name,
        internalNotes: item.internalNotes,
        number: item.number,
        address,
      };

      return location;
    });

    const resp = await executeMutation({
      variables: {
        id,
        input: {
          ...formData,
          locations,
        },
      },
    });

    if (resp) {
      Swal.fire({
        icon: 'success',
        title: 'Update successful!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.push('/customers');
      });
    }
  };

  return (
    <Layout>
      <Seo title="Edit Customer Page" description="Edit Customer data" />
      <DashboardMenu heading="Edit Customer">
        {loading && !data ? (
          <Loading />
        ) : (
          <CustomerForm
            enableReinitialize
            initialValues={customerData}
            onSubmit={handleSubmit}
            locations={customerData.locations}
          />
        )}
      </DashboardMenu>
    </Layout>
  );
};

export default EditCustomer;
