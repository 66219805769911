import React from 'react';
import randomstring from 'randomstring';

const RadioSelect = ({ label, value, onChange, options, size }) => (
  <div className="mt-4">
    <div className="columns is-vcentered">
      {label && (
        <div className={`column ${size || 'is-two-thirds'} `}>
          <div className="field">
            <div className="control">
              <p className="subtitle is-6 has-text-weight-medium mt-0 mb-0">
                {label}
              </p>
            </div>
          </div>
        </div>
      )}
      {options &&
        options.map((item) => (
          <div
            className="column is-flex is-align-items-center"
            key={randomstring.generate({
              length: 4,
            })}>
            <p className="subtitle is-6 has-text-weight-medium mb-0 mr-2">
              {item.label}
            </p>
            <input
              type="checkbox"
              checked={value === item.label}
              onChange={() => onChange(item.label)}
              className="checkbox"
            />
          </div>
        ))}
    </div>
  </div>
);

export default RadioSelect;
