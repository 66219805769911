import React from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import config from '../../utils/config';

const Container = styled.div`
  height: 40vh;
  width: 100%;
`;

const Point = styled.p`
  height: 65px;
  width: 65px;
  background-color: ${(props) => props.theme.markerColor};
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  margin-left: 3px;
  color: ${(props) => props.theme.mainBrandColor};
`;

const Location = ({ address }) => (
  <>
    {address && (
      <>
        {/* <ToolTip className="p-4 has-background-white has-border-radius-medium has-shadow-large">
          <span className="text">
            <EventAddress address={address} />
          </span>
        </ToolTip> */}
        <Point className="is-flex is-align-items-center is-justify-content-center is-size-6">
          <i className="far fa-dot-circle" />
        </Point>
      </>
    )}
  </>
);

const Map = ({ address }) => {
  const locationCoordinates = address.location.coordinates;

  const lat = isEmpty(locationCoordinates) ? 52.477564 : locationCoordinates[0];
  const lng = isEmpty(locationCoordinates)
    ? -2.0037134
    : locationCoordinates[1];

  return (
    <Container className="mb-4">
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.googleMapsKey }}
        defaultCenter={{
          lat,
          lng,
        }}
        center={{
          lat,
          lng,
        }}
        defaultZoom={15}>
        {/* <Location lat={location.lat} lng={location.lng} address={address} />
         */}
        <Location address={address} />
      </GoogleMapReact>
    </Container>
  );
};
export default Map;
