import { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import axios from 'axios';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { remove } from 'lodash';

import { isOnline } from '../utils/helpers';

const signFileUploadMutation = gql`
  mutation signFileUpload($fileName: String!, $fileType: String!) {
    signFileUpload(fileName: $fileName, fileType: $fileType) {
      signedUrl
      fileUrl
    }
  }
`;

const useImageUploading = () => {
  const uploadingQueueData = useStoreState(
    (state) => state.uploadingQueue.data,
  );
  const updateUploadingQueue = useStoreActions(
    (actions) => actions.uploadingQueue.update,
  );


  const [fileName, setFileName] = useState(undefined);
  const [fileType, setFileType] = useState(undefined);
  const [file, setFile] = useState(undefined);
  const [signFileUpload, { data: signedFileData }] = useMutation(
    signFileUploadMutation,
  );
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(undefined);


  // Update the uploading queue add and remove files from queue
  const handleUpdatingUploadQueue = (removeUploadingFile = false) => {
  
    
    
    // eslint-disable-next-line no-var
    var UploadingFilesQueue = [...uploadingQueueData];
    if (!removeUploadingFile) {
      UploadingFilesQueue.push({
        fileName,
        fileType,
        file,
      });
      setUploadedFileUrl(
        `https://rd-glazing.s3-us-east-2.amazonaws.com/${fileName}`,
      );
      setFileName(undefined);
      setFileType(undefined);
      setFile(undefined);
    } else {
      const updatedQueue = remove(
        UploadingFilesQueue,
        (item) => item.fileName !== removeUploadingFile,
      );

      UploadingFilesQueue = updatedQueue;
    }

    updateUploadingQueue(UploadingFilesQueue);
  };

  // upload  image to the aws s3 bucket
  const handleUpload = async ({ signedUrl, fileUrl }, uploadingFile) => {
    const options = {
      headers: {
        'Content-Type': fileType,
      },
    };

    await axios
      .put(signedUrl, uploadingFile, options)
      .then(() => {
        console.log('file uploaded successfully');
        setUploadedFileUrl(fileUrl);
        setFileName(undefined);
        setFileType(undefined);
        setFile(undefined);
        setTimeout(() => {
          setIsUploading(false);
          handleUpdatingUploadQueue(fileName);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // give signed urls for uploading to s3 bucket
  const handleSigningFile = async (name, type) => {
    
    setIsUploading(true);
    await signFileUpload({
      variables: {
        fileName: name,
        fileType: type,
      },
    });
  };

  // Watch the response of the signedFile mutation
  useEffect(() => {
    if (signedFileData && signedFileData.signFileUpload && file) {
      handleUpload({ ...signedFileData.signFileUpload }, file);
    }
  }, [signedFileData]);

  // Trigger the signedFile mutation and adding files the uploading queue if offline
  useEffect(async () => {
    if (fileName && fileType && isOnline() && file) {
      handleSigningFile(fileName, fileType, file);
    } else if (fileName && fileType && !isOnline() && file) {
      handleUpdatingUploadQueue();
    }
  }, [fileName, fileType, file]);

  return [
    uploadedFileUrl,
    isUploading,
    {
      setFile,
      setFileName,
      setFileType,
    },
  ];
};

export default useImageUploading;
