import currency from 'currency.js';

import config from './config';

export const formatCurrency = (value) =>
  currency(parseFloat(value), {
    symbol: `${config.currency}`,
    formatWithSymbol: true,
  }).format();

export const log = (value) => console.log(value); // eslint-disable-line

export const formatLocation = (address) => {
  const location = {};

  location.addressLine1 = address.addressLine1 ? address.addressLine1 : '';
  location.addressLine2 = address.addressLine2 ? address.addressLine2 : '';
  location.city = address.city ? address.city : '';
  location.state = address.state && address.state ? '' : '';
  location.postcode = address.postcode ? address.postcode : '';
  location.country = address.country ? address.country : '';
  location.formattedAddress = address.formattedAddress
    ? address.formattedAddress
    : '';
  location.location = {
    coordinates:
      address.location && address.location.coordinates
        ? address.location.coordinates
        : '',
  };

  return location;
};

export const isOnline = () => window.navigator.onLine;
