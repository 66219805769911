import React from 'react';
import styled from 'styled-components';

const Group = styled.div`
  width: 100%;
`;

const Input = styled.input`
  padding: 1.2rem 0.9rem;
  border: 1px solid ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.secondaryColor};
  ::placeholder {
    color: ${(props) => props.theme.placeholderColor};
  }
`;

const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizeSmall};
`;

const InputGroup = ({
  label,
  errors,
  lightLabel,
  children,
  maxLength,
  ...otherProps
}) => (
  <Group className="field">
    {label && (
      <Label
        className={`label has-text-weight-semibold mb-2 ${
          lightLabel ? 'has-text-white' : 'has-text-black'
        }`}>
        {label}
      </Label>
    )}
    <div className="control">
      {children || (
        <Input
          className={`input is-size-8 is-radiusless
           ${errors ? 'is-danger' : ''} `}
          {...otherProps}
          maxLength={maxLength}
        />
      )}
      {errors && <p className="help is-danger">{errors}</p>}
    </div>
  </Group>
);

export default InputGroup;
