import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  Button,
  TextArea,
  DatePicker,
  InputGroup,
  Select,
  RadioSelect,
  Loading,
  FileGroup,
} from '../elements';
import { formatLocation } from '../../utils/helpers';
import OfflineImageUploading from '../global/OfflineImageUploading';

import {
  CustomersDropDown,
  ContractorsDropDown,
  LocationDropDown,
} from '../dropDowns';

const PreviewText = styled.input`
  padding: 1.2rem 0.9rem;
  background-color: ${(props) => props.theme.secondaryColor};
  ::placeholder {
    color: ${(props) => props.theme.placeholderColor};
  }
`;

const CreateJobForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    isSubmitting,
    handleBlur,
    handleSubmit,
    setFieldValue,
    loading,
    hasMailButtons,
    handleSendMail,
    hasDateReceivedDisabled,
    hasMailCheckBox,
    hasUrgencyLevelDisabled,
    hasHideDateReceived,
    hasHideCustomerSelection,
    hasWorkDetailsDisabled,
    hadHideCustomerNotes,
    hasJobNotesDisabled,
    hasBookedDateDisabled,
    hasHideSubmitButton,
    handleSendSms,
  } = props;

  const [siteAddress, setSiteAddress] = useState();

  const levels = [
    { value: 1, label: 'UL-1' },
    { value: 2, label: 'UL-2' },
    { value: 3, label: 'UL-3' },
    { value: 4, label: 'UL-4' },
  ];

  const mailOptions = [
    { label: 'Send Office Email' },
    { label: 'Send Out of Hours Email' },
  ];
  const smsOption = [{ label: 'Send Out SMS' }];


  return (
    <form onSubmit={handleSubmit}>
      <OfflineImageUploading />

      {!hasHideDateReceived && (
        <div className="field-body mb-5">
          <DatePicker
            label="Date received"
            value={values.startDate}
            onChange={(date) => setFieldValue('startDate', date)}
            dateFormat="MMMM d, yyyy"
            disabled={hasDateReceivedDisabled}
          />
          <DatePicker
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            label="Time received"
            value={values.startDate}
            onChange={(date) => setFieldValue('startDate', date)}
            dateFormat="h:mm aa"
            disabled={hasDateReceivedDisabled}
          />
        </div>
      )}

      {!hasHideCustomerSelection && (
        <>
          <div className="field-body">
            <CustomersDropDown
              label="Customer"
              name="customer"
              value={values.customer}
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue('customer', { id: e.value, label: e.label })
              }
              errors={
                errors.customer && touched.customer
                  ? errors.customer
                  : undefined
              }
            />

            <ContractorsDropDown
              name="contractor"
              label="Job Assigned to:"
              value={values.contractor}
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue('contractor', { id: e.value, label: e.label })
              }
              errors={
                errors.contractor && touched.contractor
                  ? errors.contractor
                  : undefined
              }
            />
          </div>
          <div className="field-body">
            <InputGroup
              placeholder="Customer Reference"
              label="Customer Reference"
              name="customerReference"
              type="text"
              value={values.customerReference}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                errors.customerReference && touched.customerReference
                  ? errors.customerReference
                  : undefined
              }
            />
            <LocationDropDown
              name="site"
              label="Site"
              value={values.site}
              customerId={values.customer.id}
              onBlur={handleBlur}
              onChange={(e) => {
                setSiteAddress(e.value.address?.formattedAddress);
                setFieldValue('customerNotes', e.value.internalNotes);
                setFieldValue('site', {
                  label: e.value.label,
                  ...e.value.address,
                });
              }}
              errors={errors.site && touched.site ? errors.site : undefined}
            />
          </div>
          <div className="field-body is-3">
            <p className="column"> </p>
            {true && (
              <PreviewText
                className="column input is-size-8 is-radiusless  "
                defaultValue={values.site?.formattedAddress}
                value={siteAddress}
              />
            )}
          </div>
        </>
      )}

      <TextArea
        label="Details Of Work"
        name="details"
        type="text"
        value={values.details}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.details && touched.details ? errors.details : undefined}
        disabled={hasWorkDetailsDisabled}
      />

      {!hadHideCustomerNotes && (
        <TextArea
          name="customerNotes"
          label="Customer Notes"
          type="text"
          placeholder="Customer Notes"
          value={values.customerNotes}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      )}

      <TextArea
        label="Jobs Notes"
        name="internalNotes"
        type="text"
        placeholder="New notes can be added here that are job specific"
        value={values.internalNotes}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={
          errors.internalNotes && touched.internalNotes
            ? errors.internalNotes
            : undefined
        }
        disabled={hasJobNotesDisabled}
      />
      <Select
        placeholder="Select..."
        label="Urgency Level"
        name="urgencyLevel"
        options={levels}
        type="number"
        value={values.urgencyLevel}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={
          errors.urgencyLevel && touched.urgencyLevel
            ? errors.urgencyLevel
            : undefined
        }
        disabled={hasUrgencyLevelDisabled}
      />
      <DatePicker
        halfWidth
        label="Date booked on"
        value={values.dueDate}
        onChange={(date) => setFieldValue('dueDate', date)}
        dateFormat="MMMM d, yyyy"
        disabled={hasBookedDateDisabled}
      />
      <FileGroup
        label="Upload history (pdf/jpg)"
        value={values.historyFile}
        placeholder="Upload history (pdf/jpg)"
        values={values}
        onChange={(val) =>
          setFieldValue('historyFile', [...values.historyFile, val])
        }
        errors={
          errors.historyFile && touched.historyFile
            ? errors.historyFile
            : undefined
        }
      />

      {hasMailCheckBox && (
        <div className="mb-5 mt-5">
          <RadioSelect
            size="is-one-third"
            onChange={(val) => setFieldValue('mailType', val)}
            value={values.mailType}
            options={mailOptions}
          />

      <div className="mb-5 mt-5">
        <RadioSelect
          size="is-one-third"
          onChange={(val) => setFieldValue('smsType', val)}
          value={values.smsType}
          options={smsOption}
        />
      </div>
        </div>
      )}

      <div className="buttons mb-3 mt-4">
        {hasMailButtons && (
          <>
            <Button dark onClick={() => handleSendMail('inOfOffice')}>
              <span className="has-text-weight-bold">Send Office Email</span>
            </Button>
            <Button danger onClick={() => handleSendMail('outOfOffice')}>
              <span className="has-text-weight-bold">
                Send Out Of Hours Email
              </span>
            </Button>
          </>
        )}
        {hasMailButtons && (
          <>
            <Button secondary onClick={() => handleSendSms()}>
              <span className="has-text-weight-bold">Send SMS</span>
            </Button>
          </>
        )}
      </div>
      {loading && <Loading />}

      {!loading && !hasHideSubmitButton && (
        <Button disabled={isSubmitting} primary type="submit">
          <span className="has-text-weight-bold">Submit</span>
        </Button>
      )}
    </form>
  );
};

CreateJobForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  // isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    startDate: initialValues ? new Date(initialValues.startDate) : new Date(),
    dueDate:
      initialValues && initialValues.dueDate
        ? new Date(initialValues.dueDate)
        : '',
    customer: initialValues ? initialValues.customer : '',
    customerNotes:
      initialValues && initialValues.customerNotes
        ? initialValues.customerNotes
        : '',
    contractor: initialValues ? initialValues.contractor : '',
    customerReference: initialValues ? initialValues.customerReference : '',
    details:
      initialValues && initialValues.description
        ? initialValues.description
        : '',
    historyFile:
      initialValues && initialValues.files && initialValues.files.historyFile
        ? initialValues.files.historyFile
        : [],
    urgencyLevel:
      initialValues && initialValues.urgencyLevel
        ? initialValues.urgencyLevel
        : '',
    site: initialValues ? initialValues.site : '',
    internalNotes:
      initialValues && initialValues.internalNotes
        ? initialValues.internalNotes
        : '',
    mailType: '',
    smsType: '',
  }),
  validationSchema: yup.object().shape({
    customer: yup.object().required('Customer is required!'),
    site: yup.object().required('Site are required'),
    contractor: yup.object().required('Contractor is required!'),
    urgencyLevel: yup.number().required('Urgency Level is required!'),
    customerReference: yup.string(),
    details: yup.string(),
    internalNotes: yup.string(),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    const formattedLocation = formatLocation(values.site);
    props
      .onSubmit({
        startDate: values.startDate,
        urgencyLevel: parseInt(values.urgencyLevel, 10),
        dueDate: values.dueDate,
        customerReference: values.customerReference,
        customerNotes: values.customerNotes,
        customer: values.customer.id,
        contractor: values.contractor.id,
        description: values.details,
        files: {
          historyFile: [...values.historyFile],
        },
        internalNotes: values.internalNotes,
        mailType: values.mailType,
        smsType: values.smsType,
        location: {
          name: values.site.label,
          ...formattedLocation,
        },
      })
      .then(() => {
        setSubmitting(false);
      });
  },
  displayName: 'CreateJobForm', // helps with React DevTools
})(CreateJobForm);
