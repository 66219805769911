import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useSearchFilter from '../../hooks/useSearchFilter';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Loading, EmptyState } from '../../components/elements';
import { UsersTable } from '../../components/users';
import DashboardMenu from '../../components/global/DashboardMenu';

const allUserQuery = gql`
  query allUsers($limit: Int) {
    allUsers(limit: $limit) {
      id
      email
      telephone
      status
      type
      profile {
        fullName
      }
      account {
        accountAddress
      }
    }
  }
`;

const Users = () => {
  const [limit, setLimit] = useState('ALL');

  const [filteredData, { onSearch, setAllData }] = useSearchFilter([
    'profile.fullName',
    'email',
    'telephone',
    'status',
    'type',
  ]);

  const { data, error, loading } = useQuery(allUserQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: limit === 'ALL' ? 0 : parseInt(limit, 10),
    },
  });

  useEffect(() => {
    if (error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
  }, [error]);

  useEffect(() => {
    if (data && data.allUsers) {
      setAllData(data.allUsers);
    }
  }, [data]);

  return (
    <Layout>
      <Seo title="Users" description="View all users" />
      <DashboardMenu
        hasSearchMenu
        heading="Users"
        onChange={onSearch}
        onSelect={(e) => setLimit(e.target.value)}
        selectValue={limit}>
        {loading && !data && <Loading />}
        <div className="has-text-centered mb-5">
          {filteredData.length === 0 && !loading && <EmptyState />}
        </div>
        {filteredData && filteredData.length > 0 && (
          <UsersTable data={filteredData} />
        )}
      </DashboardMenu>
    </Layout>
  );
};
export default Users;
