import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorkerRegistration from './utils/serviceWorkerRegistration';
import reportWebVitals from './utils/reportWebVitals';
import './utils/sentry';

ReactDOM.render(<App />, document.getElementById('root'));

// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
// if (config.debug) {
//   serviceWorker.unregister();
// } else {
//   serviceWorker.register();
// }
reportWebVitals();
