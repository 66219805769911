/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import apolloLogger from 'apollo-link-logger';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createHttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import QueueLink from 'apollo-link-queue';
import SerializingLink from 'apollo-link-serialize';

import fetch from 'isomorphic-fetch';

import config from './config';
import sentry from './sentry';

const cache = new InMemoryCache();
export const queueLink = new QueueLink();
const serializingLink = new SerializingLink();

export const apolloPersistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
  debug: config.debug,
  maxSize: false,
  key: 'rdGlazing-apollo-graphql',
  // trigger: 'background',
});

const httpLink = createHttpLink({
  uri: config.debug ? config.graphQlUriDev : config.graphQlUri,
  fetch,
});

const authLink = setContext(async (_, { headers }) => {
  const token = process.browser
    ? window.localStorage.getItem('token')
    : undefined;

  // console.log('token', token);

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 1500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: Infinity,
    retryIf: (error) => !!error,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('onError 22');
  if (config.debug) {
    // eslint-disable-next-line no-console
    console.log('onError', graphQLErrors, networkError);
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      sentry.captureException(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      ),
    );
  }
  if (networkError) {
    sentry.captureException(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    // new SentryLink(),
    errorLink,
    apolloLogger,
    serializingLink,
    retryLink,
    queueLink,
    authLink.concat(httpLink),
  ]),
  cache,
  // queryDeduplication: false,
});

export default client;
