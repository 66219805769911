import React from 'react';
import { isEmpty } from 'lodash';
import { usePDF, PDFDownloadLink } from '@react-pdf/renderer';

import PdfView from './PdfView';
import { Loading } from '../elements';

const JobPdf = ({ jobData }) => {
  const [instance] = usePDF({ document: PdfView });

  const contractorName =
    jobData && jobData.contractor
      ? jobData.contractor.profile.fullName
      : 'undefined';

  const riskAssessmentsOptions = [
    {
      title:
        'I have read and understand the generic risk assessments and method statements.',
      value: '',
    },
    {
      title:
        'I have carried out a site-specific risk assessment and can confirm that I am working in a safe environment.',
      value: '',
    },
    {
      title: 'Is there any risks to customers, visitors or the public?',
      value: '',
    },
    {
      title:
        'Is there a suspicion of asbestos? If yes, please answer the question below to confirm that you have received asbestos awareness training and can continue to work safely.',
      value: '',
    },
    {
      title:
        'Yes, I have received asbestos awareness training and continue to work safely.',
      value: '',
    },
    {
      title: 'Who will be working with you?',
      value: '',
    },
  ];

  const riskAssessments =
    jobData && jobData.riskAssessments && !isEmpty(jobData.riskAssessments)
      ? jobData.riskAssessments
      : riskAssessmentsOptions;

  if (instance.loading)
    return (
      <div>
        <Loading />
      </div>
    );

  if (instance.error) return <div>Something went wrong</div>;

  return (
    <div>
      {isEmpty(jobData) && (
        <div className="has-text-centered">
          <h1 className="title is-1 has-text-info">Please wait...</h1>
          <Loading type="spin" />
        </div>
      )}

      {jobData && (
        <PDFDownloadLink
          document={
            <PdfView
              jobData={jobData}
              contractorName={contractorName}
              riskAssessments={riskAssessments}
            />
          }
          fileName={`${jobData.id}.pdf`}
        >
          {({ loading }) =>
            loading ? (
              <div className="has-text-centered">
                <h1 className="title is-1 has-text-info">Please wait...</h1>
                <Loading type="spin" />
              </div>
            ) : (
              <div className="has-text-centered">
                <h1 className="title is-2 has-text-success">
                  Success! Please download your PDF.
                </h1>
                <button type="button" className="button is-success is-medium">
                  Download
                </button>
              </div>
            )
          }
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default JobPdf;
