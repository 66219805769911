/* eslint no-buffer-constructor: 0 */

import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import randomstring from 'randomstring';
import useImageUploading from '../../hooks/useImageUploading';
import Loading from './Loading';

const CanvasWrapper = styled.div`
  width: 100%;
  .sigCanvas {
    width: 100%;
    height: 135px !important;
    background-color: ${(props) => props.theme.secondaryColor};
    border: 1px solid ${(props) => props.theme.borderColor};
  }
  label {
    font-size: ${(props) => props.theme.fontSizeSmall};
  }
`;

const SignaturePad = ({ label, value, handleChange }) => {
  const padRef = useRef({});
  const [imageDataURL, setImageDataURL] = useState(value);
  const [uploadedFileUrl, isUploading, { setFile, setFileName, setFileType }] =
    useImageUploading();

  useEffect(() => {
    if (uploadedFileUrl) {
      setImageDataURL(uploadedFileUrl);
      handleChange(uploadedFileUrl);
    }
  }, [uploadedFileUrl]);

  const handleClear = () => {
    padRef.current.clear();
  };

  const handleSave = () => {
    if (typeof padRef.current.getTrimmedCanvas() === 'undefined') {
      return;
    }
    // image in dataUrl
    const theBlob = padRef.current.getTrimmedCanvas().toDataURL();
    const blob = new Buffer(
      theBlob.replace(/^data:image\/\w+;base64,/, ''),
      'base64',
    );
    // console.log('file', blob);
    setFile(blob);
    setFileName(
      `${randomstring.generate({
        length: 12,
        charset: 'alphabetic',
      })}.png`,
    );
    setFileType('image/png');
  };

  return (
    <CanvasWrapper className="field mb-4">
      {label && (
        <label className="label has-text-weight-semibold has-text-black">
          {label}
        </label>
      )}

      {isUploading && (
        <div className="loading">
          <Loading />
        </div>
      )}
      {imageDataURL && !isUploading && <img src={imageDataURL} alt="sign" />}

      {!imageDataURL && !isUploading && (
        <SignatureCanvas
          penColor="black"
          canvasProps={{ className: 'sigCanvas' }}
          ref={padRef}
        />
      )}

      {!imageDataURL && (
        <div className="field is-grouped">
          <p className="control">
            <button
              className="button is-primary is-small"
              type="button"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </p>
          <p className="control">
            <button
              className="button is-danger is-small"
              type="button"
              onClick={() => {
                handleClear();
              }}
            >
              Clear
            </button>
          </p>
        </div>
      )}
    </CanvasWrapper>
  );
};

export default SignaturePad;
