import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

import theme from '../../utils/theme';

const styles = StyleSheet.create({
  Section: {
    marginTop: 28,
    // backgroundColor: 'red',
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  wrapper: {
    paddingHorizontal: 5,
    paddingVertical: 12,
    borderColor: theme.borderColor,
    borderWidth: 1,
    // alignItems: 'center',
    justifyContent: 'center',
    width: '25%',
  },
  text: {
    fontWeight: 'bold',
    fontSize: theme.fontSizeExtraSmall,
  },
});

const DetailItem = ({ data }) => (
  <>
    <View
      style={[
        styles.wrapper,
        { backgroundColor: theme.lightShades, width: '75%' },
      ]}
    >
      <Text style={styles.text}>{data.title}</Text>
    </View>
    <View style={styles.wrapper}>
      <Text style={styles.text}>{data.value}</Text>
    </View>
  </>
);

const Questions = ({ riskAssessments }) => (
  <View style={styles.Section}>
    <View style={styles.container}>
      {riskAssessments.map((item) => (
        <DetailItem key={item.title} data={item} />
      ))}
    </View>
  </View>
);

export default Questions;
