export default {
  debug: process.env.NODE_ENV === 'development',
  siteName: 'RD Glazing',
  description:
    'We are a Cannock based company offering many different services including, Boarding & Glazing and Roller Shutter installation, maintenance and servicing.',
  websiteUrl: 'https://www.rdglazing.uk',
  metaLogo: '/images/favicon.ico',
  graphQlUri:
    process.env.REACT_APP_WEB_URL ||
    'https://glazing-rd-api.vercel.app/api/graphql',
  // graphQlUriDev: 'https://rdglazing-api.vercel.app/api/graphql',
  // graphQlUriDev: 'https://rdglazing-api-accentuate.vercel.app/api/graphql',
  graphQlUriDev: 'http://localhost:4000/api/graphql',
  googleMapsKey: 'AIzaSyCEgxRL86WY0wClUD2M7b2wwPvE_7Rpf0c',
  projectKey: 'rd-glazing-app',
  oneSignalKey: 'dbec8a55-e8f1-4cbf-a3fd-f89e1c7b644c',
  currency: '£',
};
