import React from 'react';
import styled from 'styled-components';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerWrapper = styled.div`
  width: 100% !important;

  .react-datepicker-wrapper {
    display: flex !important;
  }

  input {
    width: ${(props) => (props.halfWidth ? '50%' : '100%')};
    border-radius: 0;
    background-color: ${(props) => props.theme.secondaryColor};
  }
`;

const DateTimePicker = ({
  label,
  onChange,
  value,
  halfWidth,
  dateFormat,
  disabled,
  ...otherProps
}) => {
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <DatePickerWrapper className="field" halfWidth={halfWidth}>
      {label && (
        <label className="label is-family-primary	 has-text-weight-semibold has-text-black">
          {label}
        </label>
      )}
      <DatePicker
        className="input"
        selected={value}
        onChange={onChange}
        filterTime={filterPassedTime}
        dateFormat={dateFormat || 'MMMM d, yyyy h:mm aa'}
        disabled={disabled}
        {...otherProps}
      />
    </DatePickerWrapper>
  );
};
export default DateTimePicker;
