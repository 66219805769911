import React from 'react';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import styled from 'styled-components';

import { useQuery, useMutation } from '@apollo/client';
import Layout from '../../components/Layout';
import DashboardMenu from '../../components/global/DashboardMenu';
import Seo from '../../components/Seo';
import { Loading, Heading } from '../../components/elements';
import { JobForm, JobSurveyForm } from '../../components/jobs';

import { isOnline } from '../../utils/helpers';

const Spacing = styled.hr`
  margin: 3rem 0;
  border: 1px solid ${(props) => props.theme.mainBrandColor};
`;

const jobQuery = gql`
  query job($id: ID!) {
    job(id: $id) {
      id
      startDate
      dueDate
      code
      urgencyLevel
      riskAssessmentNotes
      files {
        afterPicture
        beforePicture
        historyFile
      }
      location {
        name
        addressLine1
        addressLine2
        city
        state
        postcode
        country
        formattedAddress
        location {
          coordinates
        }
      }
      riskAssessments {
        title
        value
      }
      customer {
        id
        name
      }
      contractor {
        id
        profile {
          fullName
        }
      }
      surveys {
        mensToComplete
        hoursToComplete
        details
        surveyPicture
        outOfHours
      }
      customerReference
      startDate
      status
      internalNotes
      signedBy
      description
      locationInBuilding
      hasRevisit
    }
  }
`;

const updateJobMutation = gql`
  mutation updateJob($id: ID!, $input: JobInput!) {
    updateJob(id: $id, input: $input) {
      id
    }
  }
`;

const JobSurvey = ({ match, history }) => {
  const { id } = match.params;
  const { data, loading } = useQuery(jobQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  const [executeMutation] = useMutation(updateJobMutation);

  const jobData = data && data.job ? data.job : {};

  const handleSubmit = (formData) => {
    if (isOnline()) {
      executeMutation({
        variables: {
          id,
          input: {
            startDate: jobData.startDate,
            dueDate: jobData.dueDate,
            customer: jobData.customer.id,
            contractor: jobData.contractor.id,
            ...formData,
          },
        },
      });
    } else {
      executeMutation({
        variables: {
          id,
          input: {
            startDate: jobData.startDate,
            dueDate: jobData.dueDate,
            customer: jobData.customer.id,
            contractor: jobData.contractor.id,
            ...formData,
          },
        },
      });
    }
    Swal.fire({
      icon: 'success',
      title: 'Job updated successfully',
      showConfirmButton: false,
      timer: 1500,
    });
    history.push(`/subContractor/dashboard`);
  };

  return (
    <Layout>
      <Seo
        title="Contractor work completion"
        description="Contractor dashboard work completion information"
      />
      <DashboardMenu heading="Work Completion">
        {loading && <Loading />}
        {!loading && (
          <>
            <JobForm
              enableReinitialize
              hasUrgencyLevelDisabled
              hasHideDateReceived
              hasHideCustomerSelection
              hasWorkDetailsDisabled
              hadHideCustomerNotes
              hasJobNotesDisabled
              hasBookedDateDisabled
              hasHideSubmitButton
              initialValues={{
                ...jobData,
                customer: {
                  label: jobData.customer.name,
                  ...jobData.customer,
                },
                contractor: {
                  label: jobData.contractor.profile.fullName,
                  ...jobData.contractor,
                },
                site: {
                  label: jobData.location.name,
                  ...jobData.location,
                },
              }}
              // loading={res.loading}
              // handleSendMail={handleSendMail}
            />
            <Spacing />
            <Heading>Contractor Data</Heading>
            <JobSurveyForm
              enableReinitialize
              initialValues={jobData}
              onSubmit={handleSubmit}
            />
          </>
        )}
      </DashboardMenu>
    </Layout>
  );
};

export default JobSurvey;
