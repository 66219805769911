import React from 'react';
import styled from 'styled-components';

const StatusWrapper = styled.span`
  width: 88%;
`;

const data = [
  { id: 1, statusType: 'active', color: 'is-success' },
  { id: 2, statusType: 'notActive', color: 'is-warning' },
  { id: 3, statusType: 'revisit', color: 'is-info' },
  { id: 4, statusType: 'open', color: 'is-success' },
  { id: 5, statusType: 'close', color: 'is-success is-light' },
  { id: 6, statusType: 'archived', color: 'is-danger' },
];

const Tag = ({ status }) => (
  <div>
    {data.map((item) => (
      <span key={item.id}>
        {status === item.statusType && (
          <StatusWrapper
            className={`tag is-capitalized has-text-weight-semibold	 ${item.color}`}>
            {status}
          </StatusWrapper>
        )}
      </span>
    ))}
  </div>
);

export default Tag;
