import React from 'react';

import { InputGroup } from '../elements';

function SearchBar(props) {
  const { value, hideTitle, onChange } = props;

  return (
    <div>
      <InputGroup
        placeholder="Search..."
        label={!hideTitle ? 'Search:' : ''}
        type="text"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default SearchBar;
