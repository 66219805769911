import React from 'react';
import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';

import theme from '../../utils/theme';
import placeholder from '../../assets/images/placeholder.png';

const styles = StyleSheet.create({
  Section: {
    marginTop: 28,
    flexDirection: 'row',
  },
  imageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  wrapper: {
    paddingHorizontal: 5,
    paddingVertical: 12,
    borderColor: theme.borderColor,
    width: '35%',
    borderWidth: 1,
    justifyContent: 'center',
  },
  text: {
    fontWeight: 'bold',
    fontSize: theme.fontSizeExtraSmall,
  },
});

const removeSpaces = (url) => (url ? url.replace(/\s+/g, '%20') : undefined);

const Images = ({ title, images }) => (
  <View style={styles.Section} wrap={false}>
    {console.log('images', removeSpaces(images[0]))}
    <View style={[styles.wrapper, { backgroundColor: theme.lightShades }]}>
      <Text style={styles.text}>{title}</Text>
    </View>
    <View
      style={[
        { flexGrow: 1, width: '75%' },
        styles.wrapper,
        styles.imageContainer,
      ]}
    >
      {images &&
        images.length > 0 &&
        images.map((image) => (
          <Image
            key={Math.random()}
            src={removeSpaces(image) || placeholder}
            style={[{ height: 'auto', width: '150px', margin: 2 }]}
          />
        ))}
    </View>
  </View>
);

export default Images;
