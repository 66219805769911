import React, { useEffect, useState, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { StoreProvider } from 'easy-peasy';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import 'bulma/css/bulma.css';
import 'react-toastify/dist/ReactToastify.css';
import "tui-calendar/dist/tui-calendar.css";

import theme from './utils/theme';
import apolloClient, { apolloPersistor, queueLink } from './utils/apolloClient';
import { store, persistor } from './store';
import Routes from './utils/routes';
import { Loading } from './components/elements';

const App = () => {
  const [client, setClient] = useState(undefined);
  const toastId = useRef(null);

  useEffect(() => {
    const { connection } = navigator;

    if (connection !== undefined) {
      const updateConnectionStatus = () => {
        const ifConnected = window.navigator.onLine;
        if (ifConnected) {
          // alert('Connection available');
          queueLink.open();
          toast.dismiss(toastId.current);
        } else {
          toastId.current = toast.warn(
            'No internet, application is now in offline mode.',
            {
              icon: '🌏',
              position: 'bottom-right',
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
          queueLink.close();
        }
      };
      connection.addEventListener('change', updateConnectionStatus);
    }
  }, []);

  useEffect(() => {
    async function init() {
      await apolloPersistor.restore();
      setClient(apolloClient);
    }

    init().catch(console.error);
  }, []);

  if (!client) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={client}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <StoreProvider store={store}>
          <ThemeProvider theme={theme}>
            <Routes />
            <ToastContainer />
          </ThemeProvider>
        </StoreProvider>
      </PersistGate>
    </ApolloProvider>
  );
};
export default App;
