import React from 'react';
import { Link } from 'react-router-dom';
import * as dayjs from 'dayjs';
import { RiFlag2Fill } from 'react-icons/ri';

import { TableHead, Tag } from '../elements';

const tableHeadData = [
  { id: 1, name: 'Customer Reference' },
  { id: 2, name: 'Customer' },
  { id: 3, name: 'Address' },
  { id: 5, name: 'Due Date' },
  { id: 6, name: 'Status' },
  { id: 7, name: 'Urgency level' },
  { id: 8, name: 'View' },
  { id: 9, name: 'Pdf' },
];

const AssignJobs = ({ data, hasEngineer }) => {
  if (!data) {
    return null;
  }

  const navLink = hasEngineer ? '/engineer/job' : '/subContractor/job';

  const pdfLink = hasEngineer ? '/engineer/job-pdf' : '/subContractor/job-pdf';

  return (
    <div className="table-container">
      <table className="table is-fullwidth">
        <TableHead tableHeadData={tableHeadData} />
        <tbody>
          {data.map((item) => {
            if (!item) {
              return null;
            }
            return (
              <tr key={item.id}>
                <td>{item.customerReference}</td>
                <td>{item.customer.name}</td>
                <td width="24%">{item.location.formattedAddress}</td>
                {dayjs().isSame(item.dueDate, 'day') ||
                dayjs().isBefore(item.dueDate) ? (
                  <td>{dayjs(item.dueDate).format('DD / MM / YYYY')}</td>
                ) : (
                  <td className="has-text-danger">
                    <span className="icon-text">
                      {item.dueDate
                        ? dayjs(item.dueDate).format('DD / MM / YYYY')
                        : 'Not Booked yet'}
                      <span className="icon">
                        <RiFlag2Fill />
                      </span>
                    </span>
                  </td>
                )}

                <td>
                  <Tag status={item.status} />
                </td>
                <td width="14%" className="has-text-centered">
                  UL-{item.urgencyLevel}
                </td>
                <td>
                  <Link
                    to={`${navLink}/${item.id}`}
                    className="button is-primary">
                    <span className="icon is-small">
                      <i className="fas fa-eye" />
                    </span>
                  </Link>
                </td>
                <td>
                  <Link
                    target="_blank"
                    to={`${pdfLink}/${item.id}`}
                    className="button is-primary">
                    <span className="icon is-small">
                      <i className="fas fa-eye" />
                    </span>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AssignJobs;
