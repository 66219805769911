import React from 'react';
import { Document, Page, StyleSheet, Text } from '@react-pdf/renderer';

import Header from './Header';
import WorkSheetDetails from './WorkSheetDetails';
import Questions from './Questions';
import Images from './Images';
import DetailItem from './DetailItem';
import CompletedDetails from './CompletedDetails';
import Survey from './Survey';

import theme from '../../utils/theme';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  pageTitle: {
    fontSize: theme.fontSizeLarge,
    fontWeight: 'bold',
  },
});

const PDfView = ({ jobData, riskAssessments }) => (
  <Document>
    <Page size="A4" style={styles.body} wrap>
      <Header />
      <Text style={styles.pageTitle}>Worksheet</Text>
      <WorkSheetDetails jobData={jobData} />
      <Questions riskAssessments={riskAssessments} />
      <Images
        title="Before Picture"
        images={
          jobData.files && jobData.files.beforePicture
            ? jobData.files.beforePicture
            : []
        }
      />
      <DetailItem
        title="Details of Work"
        value={jobData && jobData.description ? jobData.description : ''}
        marginTop="20px"
      />
      <CompletedDetails jobData={jobData} />
      <Images
        title="After Picture"
        images={
          jobData.files && jobData.files.afterPicture
            ? jobData.files.afterPicture
            : []
        }
      />
      {jobData.surveys &&
        jobData.surveys.length > 0 &&
        jobData.surveys.map((item) => (
          <Survey key={Math.random()} data={item} jobData={jobData} />
        ))}
      <DetailItem
        width="50%"
        title="Job Notes"
        value={jobData && jobData.internalNotes ? jobData.internalNotes : ''}
        marginTop="20px"
      />
    </Page>
  </Document>
);

export default PDfView;
