import React from 'react';
import { View, StyleSheet, Image, Text } from '@react-pdf/renderer';

import Logo from '../../assets/images/logo-blue.png';

import theme from '../../utils/theme';

const styles = StyleSheet.create({
  Section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 28,
    // backgroundColor: 'red',
  },
  logo: {
    height: '64px',
    width: '180px',
  },

  wrapper: {
    padding: 5,
    borderColor: theme.borderColor,
    width: '50%',
    borderWidth: 1,
  },

  text: {
    fontWeight: 'bold',
    fontSize: theme.fontSize,
  },
});

const Header = () => (
  <View style={styles.Section}>
    <Image src={Logo} style={styles.logo} />
    <View style={styles.wrapper}>
      <Text style={styles.text}>Invoice Number</Text>
    </View>
  </View>
);

export default Header;
