/* eslint-disable import/imports-first */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import { format } from 'date-fns';


import useSearchFilter from '../../hooks/useSearchFilter';
import useSort from '../../hooks/useSort';

import Seo from '../../components/Seo';
import Layout from '../../components/Layout';
import OfflineImageUploading from '../../components/global/OfflineImageUploading';
import { JobsTable } from '../../components/jobs';
import DashboardMenu from '../../components/global/DashboardMenu';
import { Loading, EmptyState } from '../../components/elements';


const allJobsQuery = gql`
  query allJobs($filters: JobFilters, $limit: Int) {
    allJobs(filters: $filters, limit: $limit) {
      id
      code
      startDate
      dueDate
      status
      description
      customerNotes
      internalNotes
      urgencyLevel
      location {
        name
        formattedAddress
      }
      files {
        historyFile
        afterPicture
        beforePicture
      }
      customerReference
      customer {
        id
        name
      }
      contractor {
        id
        profile {
          fullName
        }
      }
      hasRevisit
    }
  }
`;

const updateJobMutation = gql`
  mutation updateJob($id: ID!, $input: JobInput!) {
    updateJob(id: $id, input: $input) {
      id
      status
    }
  }
`;

const hideJobsMutation = gql`
  mutation hideJobs {
    bulkJobHide {
      success
    }
  }
`;

const Jobs = ({ history }) => {
  const [limit, setLimit] = useState('ALL');
  const [jobsToExport, setJobsToExport] = useState([]);
  const [liveJobsToExport, setLiveJobsToExport] = useState([]);

  const [filteredData, { onSearch, setAllData }] = useSearchFilter([
    'customerReference',
    'dueDate',
    'customer.name',
    'location.name',
    'location.formattedAddress',
    'contractor.profile.fullName',
    'status',
  ]);

  const [sortedData, { setData, handleSorting }] = useSort();

  const [executeMutation, res] = useMutation(updateJobMutation);

  const { status } = useParams();

  const heading = status === 'revisit' ? 'Jobs that need Revisit' : 'Jobs';

  const { data, error, loading, refetch } = useQuery(allJobsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        status,
      },
      limit: limit === 'ALL' ? 0 : parseInt(limit, 10),
    },
  });

  const [executeHideJobsMutation, { loading: loading1 }] =
    useMutation(hideJobsMutation);


  const handleHideJobs = async () => {
    const res2 = await executeHideJobsMutation();
    if (
      res2 &&
      res2.data &&
      res2.data.bulkJobHide &&
      res2.data.bulkJobHide.success
    ) {
      await refetch();
      await Swal.fire({
        title: 'Success',
        text: 'successfully',
        icon: 'success',
      });
    }
  };

  const handleExpotCSV = (event, done) => {
    done();
  };

  const prepareExport = (allJobs) => {
    if (allJobs.length < 1) return;
    const exportData = [];
    const liveJobs = [];

    allJobs.forEach((job) => {
      const { customer, location, contractor } = job;
      
      exportData.push({
        Customer: customer.name,
        "Site name": location.name,
        "Enginer": contractor?.profile?.fullName,
        Description: job.description,
        "Job notes": job.internalNotes,
        "customer reference": job.customerReference,
        "Booked Date": job.dueDate && format(new Date(job.dueDate),"d-MMM-R" ) ,
        UrgencyLevel: job.urgencyLevel
      });

      if (job.status === "open") {

      liveJobs.push({
        Customer: job.customer.name,
        "Site name": location.name,
        "Enginer": contractor?.profile?.fullName,
        Description: job.description,
        "Job notes": job.internalNotes,
        "customer reference": job.customerReference,
        "Booked Date": job.dueDate && format(new Date(job.dueDate),"d-MMM-R" )  ,
        UrgencyLevel: job.urgencyLevel
      })
    }
    });

    
    setJobsToExport(exportData);
    setLiveJobsToExport(liveJobs);
    
  };

 
  useEffect(() => {
    if (error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
  }, [error]);

  useEffect(() => {
    if (data) {
      const { allJobs } = data;
      prepareExport(allJobs);

      setAllData(data.allJobs);
      setData(data.allJobs);
    }
    if (
      filteredData &&
      filteredData.length > 0 &&
      sortedData &&
      sortedData.length > 0
    ) {
      setAllData(sortedData);
    }
  }, [data, sortedData]);

  useEffect(() => {
    if (res.error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.error.message,
      });
    if (error)
      Swal.fire({
        icon: 'error',
        title: 'Error...',
        text: error.message,
      });
  }, [res.error, error]);

  const handleUpdateJob = async (formData) => {
    const objUpdate = {
      customer: formData.customer,
      contractor: formData.contractor,
      startDate: formData.startDate,
      dueDate: formData.dueDate,
      status: formData.status,
    };

    if (status === 'archived') {
      objUpdate.isHidden = true;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this job',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4896FC',
      cancelButtonColor: '#e11842',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await executeMutation({
          variables: {
            id: formData.id,
            input: objUpdate,
          },
        });
        if (resp) {
          refetch();
          Swal.fire('Deleted!', 'Job deleted successfully', 'success');
        } else history.push('');
      }
    });
  };

  return (
    <Layout>
      <Seo title="Jobs information" description="View All Jobs" />

      <DashboardMenu
        onChange={onSearch}
        hasSearchMenu
        heading={heading}
        onSelect={(e) => setLimit(e.target.value)}
        selectValue={limit}
        hasHideButton={status === 'archived'}
        onClickHideJobs={handleHideJobs}
        handleExpotCSV={handleExpotCSV}
        jobsToExport={jobsToExport}
        liveJobsToExport={liveJobsToExport}
      >
        <OfflineImageUploading />
        {loading && !data && <Loading />}
        {loading1 && <Loading />}
        <div className="has-text-centered mb-5">
          {filteredData.length === 0 && !loading && <EmptyState />}
        </div>
        {filteredData && filteredData.length > 0 && (
          <JobsTable
            status={status}
            jobsData={filteredData}
            handleRemove={handleUpdateJob}
            handleSorting={handleSorting}
          />
        )}
      </DashboardMenu>
    </Layout>
  );
};
export default Jobs;
