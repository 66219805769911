import React from 'react';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Heading, Select } from '../elements';
import Searchbar from './Searchbar';

const Container = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  min-height: 100vh;
`;


const groupList = [
  { value: 'ALL', label: 'All' },
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 40, label: 40 },
  { value: 60, label: 60 },
  { value: 80, label: 80 },
  { value: 100, label: 100 },
  { value: 500, label: 500 },
];

const DashboardMenu = ({
  heading,
  children,
  hasSearchMenu,
  value,
  onChange,
  selectValue,
  onSelect,
  hasHideButton,
  onClickHideJobs,
  handleExpotCSV,
  jobsToExport,
  liveJobsToExport,
}) => {
  const { status } = useParams();

  return (
    <Container className="card">
      <div className="card-content">
        {heading && (
          <Heading
            hasHideButton={hasHideButton}
            onClickHideJobs={onClickHideJobs}
            handleExpotCSV={handleExpotCSV}
            jobsToExport={jobsToExport}
          >
            {heading}
          </Heading>
        )}

        {hasSearchMenu && (
          <div className="columns">
            <div className="column is-half">
              <Select
                placeholder="Select..."
                label="Show entries"
                options={groupList}
                value={selectValue}
                onChange={onSelect}
              />
              {status === 'open' && (
                <CSVLink
                  className="button is-primary"
                  asyncOnClick
                  data={liveJobsToExport}
                  // onClick={handleExpotCSV}
                  filename="LiveJobs.csv"
                >
                  Export to CSV
                </CSVLink>
              )}
            </div>

            <div className="column is-half">
              <div className="field">
                <Searchbar value={value} onChange={onChange} />
              </div>
            </div>
          </div>
        )}
        {children}
      </div>
    </Container>
  );
};

export default DashboardMenu;
