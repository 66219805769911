import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { DropDown } from '../elements';

const allCustomerQuery = gql`
  query allCustomers {
    allCustomers {
      id
      name
    }
  }
`;

const CustomersDropDown = ({
  errors,
  value,
  onBlur,
  onChange,
  label,
  name,
}) => {
  const { data } = useQuery(allCustomerQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const allCustomers = data && data.allCustomers ? data.allCustomers : [];

  return (
    <DropDown
      label={label}
      name={name}
      defaultValue={value}
      onBlur={onBlur}
      onChange={onChange}
      options={
        allCustomers
          ? allCustomers.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          : []
      }
      errors={errors}
    />
  );
};

export default CustomersDropDown;
