import React from 'react';

import { Modal, Heading } from '../elements';
import { SurveyForm } from '../forms';

const AddSurveyModal = ({ onClose, isActive, onSubmit, value }) => (
  <Modal isActive={isActive} onClose={onClose}>
    <Heading>Survey</Heading>
    <SurveyForm onSubmit={onSubmit} initialValues={value} />
  </Modal>
);

export default AddSurveyModal;
