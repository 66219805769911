import React from 'react';
import { Modal } from '../elements';
import { AddressForm } from '../forms';

const AddLocationModal = ({ onClose, isActive, onSubmit, values }) => (
  <Modal isActive={isActive} onClose={onClose} title="Add Site">
    {isActive && (
      <AddressForm
        enableReinitialize
        onClose={onClose}
        onSubmit={onSubmit}
        initialValues={values}
      />
    )}
  </Modal>
);

export default AddLocationModal;
