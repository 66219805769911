import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';

import { Loading } from '../elements';
import useImageUploading from '../../hooks/useImageUploading';

const OfflineImageUploading = () => {
  const ifConnected = window.navigator.onLine;

  const [uploadedFileUrl, isUploading, { setFile, setFileName, setFileType }] =
    useImageUploading();

  const uploadingQueueData = useStoreState(
    (state) => state.uploadingQueue.data,
  );

  const handleSetFiles = (file, fileName, fileType) => {
    setFile(file);
    setFileName(fileName);
    setFileType(fileType);
  };

  const handleFileUpload = (index) => {
    const element = uploadingQueueData[index];
    handleSetFiles(element.file, element.fileName, element.fileType);
  };

  useEffect(() => {
    if (uploadingQueueData && uploadingQueueData.length > 0 && ifConnected) {
      handleFileUpload(0);
    }
  }, [ifConnected]);

  useEffect(() => {
    if (uploadedFileUrl && !isUploading && uploadingQueueData.length !== 0) {
      handleFileUpload(0);
    }
  }, [uploadingQueueData, uploadedFileUrl, isUploading]);

  if (isUploading) {
    return <Loading type="spin" />;
  }
  return null;
};

export default OfflineImageUploading;
