import React, { useState } from 'react';
import AddSurveyModal from '../modals/AddSurveyModal';

const SurveyItem = ({ val, handleDelete, onSubmit }) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <tr>
        <td> {val.mensToComplete}</td>
        <td>
          <button
            type="button"
            className="button is-small is-success"
            onClick={() => setActive(true)}>
            <span>Edit</span>
            <span className="icon is-small">
              <i className="fas fa-times" />
            </span>
          </button>
        </td>
        <td>
          <button
            type="button"
            onClick={handleDelete}
            className="button is-small is-danger is-outlined">
            <span>Delete</span>
            <span className="icon is-small">
              <i className="fas fa-times" />
            </span>
          </button>
        </td>
      </tr>
      <AddSurveyModal
        isActive={active}
        onClose={() => setActive(false)}
        value={val}
        onSubmit={(data) => {
          onSubmit(data);
          setActive(false);
        }}
      />
    </>
  );
};

export default SurveyItem;
