import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import Swal from 'sweetalert2';

import Seo from '../../components/Seo';
import Layout from '../../components/Layout';
import DashboardMenu from '../../components/global/DashboardMenu';
import { CustomerForm } from '../../components/customers';

const insertCustomerMutation = gql`
  mutation insertCustomer($input: CustomerInput!) {
    insertCustomer(input: $input) {
      id
    }
  }
`;

const CreateCustomer = ({ history }) => {
  const [executeMutation, res] = useMutation(insertCustomerMutation);
  useEffect(() => {
    if (res.error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.error.message,
      });
  }, [res.error]);

  const handleSubmit = async (formData) => {
    const resp = await executeMutation({
      variables: { input: formData },
    });

    if (resp) {
      Swal.fire({
        icon: 'success',
        title: 'Customer added successfully',
        showConfirmButton: false,
        timer: 1500,
      });
      history.push(`/customers`);
    }
  };

  return (
    <Layout>
      <Seo title="Add customer" description="Add customers information" />
      <DashboardMenu heading="Add Customer">
        <CustomerForm onSubmit={handleSubmit} />
      </DashboardMenu>
    </Layout>
  );
};

export default CreateCustomer;
