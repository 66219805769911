/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import XLSX from 'xlsx';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import Swal from 'sweetalert2';
import { BiLinkExternal } from 'react-icons/bi';

import Seo from '../../components/Seo';
import Layout from '../../components/Layout';
import DashboardMenu from '../../components/global/DashboardMenu';
import { JobForm } from '../../components/jobs';

const insertJobMutation = gql`
  mutation insertJob($input: JobInput!) {
    insertJob(input: $input) {
      id
    }
  }
`;

const sendJobEmailMutation = gql`
  mutation sendJobEmail($id: ID!, $mailType: mailType!) {
    sendJobEmail(id: $id, mailType: $mailType) {
      success
    }
  }
`;
const sendJobSmsMutation = gql`
  mutation sendJobEmail($id: ID!) {
    sendJobSms(id: $id) {
      success
    }
  }
`;

const CreateJob = ({ history }) => {
  const [executeMutation, res] = useMutation(insertJobMutation);
  const [executeSendMail, res2] = useMutation(sendJobEmailMutation);
  const [executeSendSms, res3] = useMutation(sendJobSmsMutation);

  useEffect(() => {
    if (res.error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.error.message,
      });
  }, [res.error]);

  const handleSubmit = async (formData) => {
    const mailOption = formData.mailType;
    const smsOption = formData.smsType;

    delete formData.mailType;
    delete formData.smsType;
    const resp = await executeMutation({
      variables: {
        input: {
          status: 'open',
          ...formData,
        },
      },
    });

    if (mailOption && resp.data.insertJob.id) {
      await executeSendMail({
        variables: {
          id: resp.data.insertJob.id,
          mailType:
            mailOption === 'Send Office Email' ? 'inOfOffice' : 'outOfOffice',
        },
      });
    }
    if (smsOption && resp.data.insertJob.id) {
      await executeSendSms({
        variables: {
          id: resp.data.insertJob.id,
        },
      });
    }
    if (resp.data) {
      Swal.fire({
        icon: 'success',
        title: 'Job added successfully',
        showConfirmButton: false,
        timer: 1500,
      });
      history.push(`/jobs/open`);
    }
  };

  const readExcel = (file) => {
    // eslint-disable-next-line no-shadow
    const promise = new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data2 = XLSX.utils.sheet_to_json(ws);
        res(data2);
      };

      fileReader.onerror = (err) => {
        rej(err);
      };
    });

    promise.then((d) => {
      d.forEach((e) => {
        console.log(e, 'excel data');
        const item = {};
        item.email = e.email;
        item.name = e.name;
        console.log(item, 'excel import data test');
      });
    });
  };

  const handleImport = (e) => {
    const file = e.target.files[0];
    readExcel(file);
  };

  return (
    <Layout>
      <Seo title="Add Job" description="Add new Job Form" />
      <DashboardMenu heading="Add Job">
        <div className="is-flex is-justify-content-space-between	">
          <Link
            to="/contractor/create"
            className="button is-small mb-4 has-text-weight-semibold">
            <span className="icon">
              <BiLinkExternal />
            </span>
            <span>Add User</span>
          </Link>
          <div className="file is-info">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="resume"
                onChange={(e) => handleImport(e)}
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload" />
                </span>
                <span className="file-label">Upload CSV</span>
              </span>
            </label>
          </div>
        </div>
        <JobForm
          hasMailCheckBox
          onSubmit={handleSubmit}
          loading={res2.loading}
        />
      </DashboardMenu>
    </Layout>
  );
};

export default CreateJob;
