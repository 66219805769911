import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';

import Seo from '../components/Seo';
import { ForgotPasswordForm } from '../components/forms';

const forgotPasswordMutation = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
    }
  }
`;

const ForgotPassword = () => {
  const [executeMutation, res] = useMutation(forgotPasswordMutation);
  useEffect(() => {
    if (res.error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.error.message,
      });
  }, [res.error]);

  return (
    <>
      <Seo title="Forgot Password" />
      <div className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6">
              <div className="card">
                <header className="card-header has-background-info-light">
                  <p className="title is-5 card-header-title has-text-white">
                    Request password reset
                  </p>
                </header>
                <div className="card-content">
                  <ForgotPasswordForm
                    onSubmit={async (data) => {
                      await executeMutation({ variables: { input: data } });
                      Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'An email has been sent, please check your Inbox',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
