import React from 'react';
import styled from 'styled-components';

import UploadImageInput from './UploadImageInput';

const Group = styled.div`
  width: ${(props) => (props.width ? '50%' : '')} !important;
  label {
    color: ${(props) => props.theme.textColorLite};
  }
`;

const FileGroup = ({ label, width, errors, ...otherProps }) => (
  <Group className="field" width={width}>
    {label && (
      <label className="label has-text-weight-semibold mb-2">{label}</label>
    )}
    <div className="control">
      <UploadImageInput {...otherProps} />
      {errors && <p className="help is-danger">{errors}</p>}
    </div>
  </Group>
);

export default FileGroup;
