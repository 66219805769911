import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const SelectInput = styled.div`
  width: 100%;
  label {
    font-size: ${(props) => props.theme.fontSizeSmall};
  }

  .css-yk16xz-control {
    border-radius: 0;
  }
  .css-1pahdxg-control {
    border-color: ${(props) => props.theme.lightAccent}!important;
    border-radius: 0;
  }
  .css-1pahdxg-control:hover {
    border-color: ${(props) => props.theme.lightAccent}!important;
  }
  .css-g1d714-ValueContainer,
  .css-tlfecz-indicatorContainer {
    background: ${(props) => props.theme.lightAccent}!important;
  }
`;

const DropDown = ({ errors, options, label, ...otherProps }) => (
  <SelectInput className="field mb-5">
    {label && (
      <label className="label has-text-weight-semibold has-text-black">
        {label}
      </label>
    )}
    <div className="control">
      <Select isClearable options={options} {...otherProps} />
    </div>
    {errors && <p className="help is-danger">{errors}</p>}
  </SelectInput>
);

export default DropDown;
