import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

import theme from '../../utils/theme';

import WorkSheetDetails from './WorkSheetDetails';
import DetailItem from './DetailItem';
import Images from './Images';

const styles = StyleSheet.create({
  Section: {
    marginTop: '50px',
    // backgroundColor: 'red',
  },
  pageTitle: {
    fontSize: theme.fontSizeLarge,
    fontWeight: 'bold',
  },
});

const Survey = ({ data, jobData }) => {
  const surveyDetails = [
    {
      key: 1,
      title: 'Hrs To Complete',
      value: data && data.hoursToComplete ? data.hoursToComplete : '',
    },
    {
      key: 2,
      title: 'In Hours/Out of Hrs',
      value: data && data.outOfHours ? data.outOfHours : '',
    },
    {
      key: 3,
      title: 'Men To Complete',
      value: data && data.mensToComplete ? data.mensToComplete : '',
    },
    {
      key: 4,
      title: 'Survey Details',
      value: data && data.details ? data.details : '',
    },
    {
      key: 5,
      title: 'Quote Ref',
      value: '',
    },
    {
      key: 6,
      title: 'Notes',
      value: '',
    },
  ];

  return (
    <View style={styles.Section} break>
      <Text style={styles.pageTitle}>Survey</Text>
      <WorkSheetDetails jobData={jobData} />
      {surveyDetails.slice(0, 4).map((item, index) => (
        <DetailItem
          width="50%"
          key={item.key}
          title={item.title}
          value={item.value}
          marginTop={index === 0 ? '20px' : '0px'}
        />
      ))}

      {data && data.surveyPicture && data.surveyPicture.length > 0 && (
        <Images title="Survey Images" images={data.surveyPicture} />
      )}
      {surveyDetails.slice(4, 6).map((item, index) => (
        <DetailItem
          width="50%"
          key={item.key}
          title={item.title}
          value={item.value}
          marginTop={index === 0 ? '20px' : '0px'}
        />
      ))}
    </View>
  );
};

export default Survey;
