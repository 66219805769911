import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { InputGroup, Button, Select } from '../elements';

const groupList = [
  { value: 'active', label: 'Active' },
  { value: 'notActive', label: 'Not Active' },
  { value: 'archived', label: 'Archived' },
];

const userTypes = [
  { value: 'engineer', label: 'Engineer' },
  { value: 'subContractor', label: 'Sub Contractor' },
  { value: 'subAdmin', label: 'Sub Admin' },
];

const UserForm = (props) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    hidePassword,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="field-body mb-5">
        <InputGroup
          placeholder="John doe"
          label="Name"
          name="fullName"
          type="text"
          value={values.fullName}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.fullName && touched.fullName ? errors.fullName : undefined
          }
        />

        <InputGroup
          placeholder="john@doe.com"
          label="Email"
          name="email"
          type="text"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.email && touched.email ? errors.email : undefined}
        />
      </div>
      <div className="field-body mb-5">
        <Select
          placeholder="Select..."
          label="Role"
          name="userType"
          options={userTypes}
          type="text"
          value={values.userType}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.userType && touched.userType ? errors.userType : undefined
          }
        />
      </div>
      <div className="field-body mb-5">
      <InputGroup
          placeholder="+44xxxxxxxx"
          label="Phone Number"
          name="telephone"
          type="tel"
          value={values.telephone}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.telephone && touched.telephone ? errors.telephone : undefined}
        />
      </div>
      <div className="field-body mb-5">
        <Select
          placeholder="Select..."
          label="Status"
          name="status"
          options={groupList}
          type="text"
          value={values.status}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.status && touched.status ? errors.status : undefined}
        />
      </div>
      {!hidePassword && (
        <InputGroup
          placeholder="**********"
          label="Password"
          name="password"
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.password && touched.password ? errors.password : undefined
          }
        />
      )}

      <div className="mb-3 mt-5">
        <Button primary type="submit" loading={isSubmitting}>
          <span className="has-text-weight-bold">Submit</span>
        </Button>
      </div>
    </form>
  );
};

UserForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    email: initialValues && initialValues.email ? initialValues.email : '',
    fullName:
      initialValues && initialValues.profile
        ? initialValues.profile.fullName
        : '',
    telephone: initialValues.telephone,
    userType: initialValues.type || 'subContractor',
    status: initialValues.status || 'active',
  }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required!'),
    status: yup.string().required('Status is required!'),
    telephone: yup.number().notRequired(),
    userType: yup.string().required('User role is required!'),
    fullName: yup.string().required('Name is required!'),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    props
      .onSubmit({
        email: values.email,
        password: values.password,
        telephone: values.telephone,
        type: values.userType,
        status: values.status,
        account: {
          accountEmail: values.email,
        },
        profile: {
          fullName: values.fullName,
        },
      })
      .then(() => {
        setSubmitting(false);
      });
  },
  displayName: 'UserForm',
})(UserForm);
