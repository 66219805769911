import React, { useCallback, useEffect, useRef, useState } from 'react';
import Calendar from '@toast-ui/react-calendar';
import { format } from 'date-fns';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import styled from 'styled-components';

import DashboardMenu from '../components/global/DashboardMenu';
import Layout from '../components/Layout';
import { Select } from '../components/elements';

const allJobsQuery = gql`
  query allJobs($filters: JobFilters, $limit: Int) {
    allJobs(filters: $filters, limit: $limit) {
      id
      code
      startDate
      dueDate
      status
      description
      location {
        name
        formattedAddress
      }
      files {
        historyFile
        afterPicture
        beforePicture
      }
      customerReference
      customer {
        id
        name
      }
      contractor {
        id
        profile {
          fullName
        }
      }
      hasRevisit
    }
  }
`;

const Heading = styled.h3`
  display: inline-block;
`;

const CalendarPage = () => {
  const { data } = useQuery(allJobsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        status: 'open',
      },
    },
  });

  const [allJobs, setAllData] = useState();
  const [calendarView, setCalendarView] = useState('month');
  const [currentCalendarMonth, setcurrentMonth] = useState();

  const calendarRef = useRef();

  const handleCalendarData = (jobs) => {
    if (jobs.length < 1) return;
    const sortedJobs = [];

    jobs.forEach((item) => {
      if (item.contractor?.profile.fullName !== 'To be assigned') {
        sortedJobs.push({
          ...item,
          start: item.dueDate,
          end: item.dueDate,
          title: `${item.contractor?.profile.fullName} - ${item.location.name} - ${item.customerReference}`,
          body: `Description: ${item.description} </br> <p class="has-text-weight-semibold">  Assign To: ${item.contractor?.profile.fullName} </p>`,
          category: 'time',
          calendarId: item.id,
          isVisible: true,
          isReadOnly: true,
          location: item.location?.formattedAddress,
          color: '#ffffff',
          bgColor: '#9e5fff',
          dragBgColor: '#9e5fff',
          borderColor: '#9e5fff',
          isAllDay: true,
        });
      }
    });
    setAllData(sortedJobs);
  };

  const calendars = [
    {
      id: '1',
      name: 'My Calendar',
      color: '#ffffff',
      bgColor: '#9e5fff',
      dragBgColor: '#9e5fff',
      borderColor: '#9e5fff',
    },
    {
      id: '2',
      name: 'Company',
      color: '#00a9ff',
      bgColor: '#00a9ff',
      dragBgColor: '#00a9ff',
      borderColor: '#00a9ff',
    },
  ];

  const levels = [
    { value: 'day', label: 'day' },
    { value: 'week', label: 'week' },
    { value: 'month', label: 'month' },
  ];

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handleOnCalendarViewChange = (eve) => {
    setCalendarView(eve.target.value);
  };

  const handleCurrentMonth = () => {
    const calendarInstace = calendarRef.current.getInstance();
    const currentDate = calendarInstace.getDate();
    const currentMonth = currentDate.getMonth();
    setcurrentMonth(months[currentMonth]);
  };

  useEffect(() => {
    if (data) {
      setAllData(data.allJobs);
      handleCalendarData(data.allJobs);
      handleCurrentMonth();
    }
  }, [data]);

  const handleNext = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.next();
    handleCurrentMonth();
  };
  const handleToday = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.today();
    handleCurrentMonth();
  };
  const handleprev = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.prev();
    handleCurrentMonth();
  };

  function _getFormattedTime(time) {
    const date = new Date(time);
    const h = date.getHours();
    const m = date.getMinutes();

    return `${h}:${m}`;
  }

  function _getTimeTemplate(schedule, isAllDay) {
    const html = [];

    if (!isAllDay) {
      html.push(`<strong>${_getFormattedTime(schedule.start)}</strong> `);
    }
    if (schedule.isPrivate) {
      html.push('<span class="calendar-font-icon ic-lock-b"></span>');
      html.push(' Private');
    } else {
      if (schedule.isReadOnly) {
        html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
      } else if (schedule.recurrenceRule) {
        html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
      } else if (schedule.attendees.length) {
        html.push('<span class="calendar-font-icon ic-user-b"></span>');
      } else if (schedule.location) {
        html.push('<span class="calendar-font-icon ic-location-b"></span>');
      }
      html.push(` ${schedule.title}`);
    }

    return html.join('');
  }

  const templates = {
    time(schedule) {
      return _getTimeTemplate(schedule, false);
    },
    popupDetailDate(_, start, end) {
      const endDate = format(new Date(end), 'E do MMMM');
      const startDate = format(new Date(start), 'E do MMMM');
      return `${startDate} - ${endDate}`;
    },
  };

  const onClickSchedule = useCallback((e) => {
    console.log(e);
  }, []);

  return (
    <Layout>
      <DashboardMenu heading="Calendar">
        <div className="container">
          <div className="my-3 is-flex-direction-row is-justify-content-space-around is-align-items-center">
            <button
              onClick={handleprev}
              type="button"
              className="button is-primary mx-1"
            >
              Prev
            </button>
            <button
              onClick={handleToday}
              type="button"
              className="button is-primary mx-1"
            >
              Today
            </button>
            <button
              onClick={handleNext}
              type="button"
              className="button is-primary mx-1"
            >
              Next
            </button>

            <Heading className="mt-1 pl-4 has-text-weight-bold">
              {currentCalendarMonth}
            </Heading>
          </div>
          <div className="my-3 ml-2 columns">
            <div className="is-one-fifth">
              <Select
                placeholder="Select..."
                label="Change View"
                name="ChangeView"
                options={levels}
                value={calendarView}
                onChange={handleOnCalendarViewChange}
              />
            </div>
          </div>
          <Calendar
            ref={calendarRef}
            // height="400px"
            useDetailPopup
            template={templates}
            calendars={calendars}
            schedules={allJobs}
            view={calendarView}
            onClickSchedule={onClickSchedule}
          />
        </div>
      </DashboardMenu>
    </Layout>
  );
};
export default CalendarPage;
