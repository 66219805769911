import React from 'react';
import { FaSort } from 'react-icons/fa';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled.button`
  border: 0;
  padding: 0;
`;

const TableHead = ({ tableHeadData, isPdfHidden, handleSorting }) => (
  <thead>
    <tr>
      {tableHeadData.map((item) => {
        if (isPdfHidden && item.name === 'PDF') {
          return null;
        }
        return (
          <th key={item.id || item.name}>
            <Wrapper
              onClick={() => {
                if (handleSorting) {
                  handleSorting(item.sortedBy);
                }
              }}
            >
              <span>{item.name}</span>
              {item.isSortOption && (
                <StyledButton
                  className="button is-small"
                  type="button"
                  onClick={() => {
                    if (handleSorting) {
                      handleSorting(item.sortedBy);
                    }
                  }}
                >
                  <FaSort />
                </StyledButton>
              )}
            </Wrapper>
          </th>
        );
      })}
    </tr>
  </thead>
);

export default TableHead;
