import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import useSearchFilter from '../../hooks/useSearchFilter';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Loading, EmptyState } from '../../components/elements';
import AssignJobs from '../../components/contractors/AssignJobs';
import DashboardMenu from '../../components/global/DashboardMenu';
import OfflineImageUploading from '../../components/global/OfflineImageUploading';

const allJobsQuery = gql`
  query allJobs($filters: JobFilters, $limit: Int) {
    allJobs(filters: $filters, limit: $limit) {
      id
      code
      dueDate
      status
      urgencyLevel
      location {
        name
        formattedAddress
      }
      customerReference
      customer {
        id
        name
      }
      contractor {
        id
        profile {
          fullName
        }
      }
    }
  }
`;

const EngineerDashboard = () => {
  const userData = useStoreState((state) => state.user.data);

  const { data, error, loading } = useQuery(allJobsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        status: 'open',
        contractorId: userData && userData.id ? userData.id : '',
      },
      limit: 10,
    },
  });
  const [filteredData, { onSearch, setAllData }] = useSearchFilter([
    'profile.fullName',
    'email',
    'telephone',
    'status',
    'account.accountAddress',
  ]);

  useEffect(() => {
    if (data) setAllData(data.allJobs);
  }, [data]);

  useEffect(() => {
    if (error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
  }, [error]);

  return (
    <Layout>
      <Seo title="Engineer Dashboard" description="Engineer Jobs" />
      <DashboardMenu
        hasSearchMenu
        heading="Engineer Dashboard"
        onChange={onSearch}
      >
        <OfflineImageUploading />
        {loading && !data && <Loading />}
        <div className="has-text-centered mb-5">
          {filteredData.length === 0 && !loading && <EmptyState />}
        </div>
        {filteredData && filteredData.length > 0 && (
          <AssignJobs data={filteredData} hasEngineer />
        )}
      </DashboardMenu>
    </Layout>
  );
};
export default EngineerDashboard;
