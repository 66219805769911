import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';

import { DropDown } from '../elements';

const singleCustomerQuery = gql`
  query singleCustomer($id: ID!) {
    customer(id: $id) {
      id
      name
      locations {
        internalNotes
        id
        name
        number
        address {
          addressLine1
          addressLine2
          city
          state
          postcode
          country
          formattedAddress
          location {
            coordinates
          }
        }
      }
    }
  }
`;

const LocationDropDown = ({
  errors,
  value,
  onBlur,
  onChange,
  label,
  name,
  customerId,
}) => {
  const [getSingleCustomer, { data }] = useLazyQuery(singleCustomerQuery, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (customerId) {
      getSingleCustomer({
        variables: {
          id: customerId,
        },
      });
    }
  }, [customerId]);

  const customer = data && data.customer ? data.customer : {};
  const customerLocations =
    customer?.locations && customer.locations.length > 0
      ? customer.locations
      : [];

  return (
    <DropDown
      label={label}
      name={name}
      defaultValue={value}
      onBlur={onBlur}
      onChange={onChange}
      options={
        customerLocations
          ? customerLocations.map((item) => ({
              value: {
                id: item.id,
                internalNotes: item.internalNotes,
                address: item.address,
                label: item.name,
              },
              label: item && item.name ? item.name : '',
            }))
          : []
      }
      errors={errors}
    />
  );
};

export default LocationDropDown;
