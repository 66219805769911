import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { InputGroup, Button, TextArea, FileGroup } from '../elements';

const SurveyForm = (props) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = props;

  return (
    <div>
      <div className="field-body mb-3">
        <InputGroup
          placeholder="Men to complete"
          label="Men to complete"
          name="mensToComplete"
          type="text"
          value={values.mensToComplete}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.mensToComplete && touched.mensToComplete
              ? errors.mensToComplete
              : undefined
          }
        />

        <InputGroup
          placeholder="Hours to complete"
          label="Hours to complete"
          name="hoursToComplete"
          value={values.hoursToComplete}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          errors={
            errors.hoursToComplete && touched.hoursToComplete
              ? errors.hoursToComplete
              : undefined
          }
        />
        <InputGroup
          placeholder="In hrs/OOH"
          label="In hrs/OOH"
          name="outOfHours"
          value={values.outOfHours}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
        />
      </div>

      <TextArea
        placeholder="Add details here"
        label="Details"
        name="details"
        type="text"
        value={values.details}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.details && touched.details ? errors.details : undefined}
      />
      <FileGroup
        value={values.surveyPicture}
        placeholder="Upload photo"
        onChange={(val) =>
          setFieldValue('surveyPicture', [...values.surveyPicture, val])
        }
      />
      <div className="mb-3 mt-5">
        <Button primary onClick={handleSubmit} loading={isSubmitting}>
          <span className="has-text-weight-bold">Submit</span>
        </Button>
      </div>
    </div>
  );
};

SurveyForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    mensToComplete: initialValues ? initialValues.mensToComplete : '',
    hoursToComplete:
      initialValues && initialValues.hoursToComplete
        ? initialValues.hoursToComplete
        : '',
    outOfHours:
      initialValues && initialValues.outOfHours ? initialValues.outOfHours : '',
    details: initialValues ? initialValues.details : ' ',
    surveyPicture: initialValues ? initialValues.surveyPicture : [],
  }),
  validationSchema: yup.object().shape({
    mensToComplete: yup.string().required('Men To Complete is required!'),
    hoursToComplete: yup.string().required('Hours To Complete is required!'),
    details: yup.string(),
  }),

  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    props.onSubmit(values);
    resetForm();
    setSubmitting(false);
  },
  displayName: 'SurveyForm', // helps with React DevTools
})(SurveyForm);
