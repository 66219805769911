import React from 'react';
import { Link } from 'react-router-dom';
import * as dayjs from 'dayjs';
import { RiDeleteBin5Line, RiFlag2Fill } from 'react-icons/ri';

import { TableHead, Tag } from '../elements';

const tableHeadData = [
  {
    id: 1,
    name: 'Customer Reference',
    isSortOption: true,
    sortedBy: 'customerReference',
  },
  {
    id: 2,
    name: 'Customer',
    isSortOption: true,
    sortedBy: 'customer.name',
  },
  { id: 3, name: 'Due Date', isSortOption: true, sortedBy: 'dueDate' },
  {
    id: 4,
    name: 'Address',
    isSortOption: true,
    sortedBy: 'location.formattedAddress',
  },
  {
    id: 5,
    name: 'Assigned',
    isSortOption: true,
    sortedBy: 'contractor.profile.fullName',
  },
  { id: 6, name: 'Status' },
  { id: 7, name: 'View' },
  { id: 8, name: 'PDF' },
  { id: 9, name: 'Archive' },
];

const JobsTable = ({ jobsData, handleRemove, status, handleSorting }) => {
  if (!jobsData) {
    return null;
  }

  return (
    <div className="table-container">
      <table className="table is-fullwidth">
        <TableHead
          tableHeadData={tableHeadData}
          isPdfHidden={status === 'open'}
          handleSorting={handleSorting}
        />
        <tbody>
          {jobsData.map((item) => {
            if (!item) {
              return null;
            }

            return (
              <tr key={item.id}>
                <td>{item.customerReference}</td>
                <td>{item.customer ? item.customer.name : ''} </td>
                {dayjs().isSame(item.dueDate, 'day') ||
                dayjs().isBefore(item.dueDate) ? (
                  <td width="19%">
                    {dayjs(item.dueDate).format('DD / MM / YYYY')}
                  </td>
                ) : (
                  <td className="has-text-danger" width="19%">
                    <span className="icon-text">
                      {dayjs(item.dueDate).format('DD / MM / YYYY')}
                      <span className="icon">
                        <RiFlag2Fill />
                      </span>
                    </span>
                  </td>
                )}
                <td width="25%">
                  {`${item.location.name}, ${item.location.formattedAddress}`}
                </td>
                <td>
                  {(item.contractor &&
                    item.contractor.profile &&
                    item.contractor.profile.fullName) ||
                    ''}
                </td>
                <td>
                  <>
                    <Tag status={item.status} />
                    {/* {item.hasRevisit && <Tag status="revisit" />} */}
                  </>
                </td>
                <td>
                  <Link to={`/job/${item.id}`} className="button is-primary">
                    View
                  </Link>
                </td>

                {status !== 'open' && (
                  <td>
                    <Link
                      to={`/admin/job-pdf/${item.id}`}
                      className="button is-primary"
                      target="_blank"
                    >
                      View
                    </Link>
                  </td>
                )}

                <td>
                  <button
                    className="button"
                    type="submit"
                    onClick={() =>
                      handleRemove({
                        id: item.id,
                        customer: item.customer.id,
                        contractor: item.contractor.id,
                        startDate: item.startDate,
                        dueDate: item.dueDate,
                        status: 'archived',
                      })
                    }
                  >
                    <RiDeleteBin5Line />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default JobsTable;
