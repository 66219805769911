import React, { useState } from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
// import * as BsIcons from 'react-icons/bs';
import { IconContext } from 'react-icons/lib';
import Logo from '../../assets/images/logo.png';
// import PushNotification from '../global/PushNotifications';
import SubMenu from './SubMenu';

const Nav = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${(props) => props.theme.mainBrandColor};
  .navbar-menu {
    top: 6rem;
    background-color: ${(props) => props.theme.mainBrandColor};
    position: absolute;
    width: 100%;
    transition: 0.6s;
    .animated-line {
      position: relative;
      padding: 0;
      &::before {
        transition: 300ms ease-out;
        height: 0.1rem;
        content: '';
        position: absolute;
        background-color: ${(props) =>
          props.theme.primaryBackground} !important;
        width: 0%;
        bottom: 1.5rem;
      }
      &:hover::before {
        width: 65%;
      }
    }
  }
`;

const NavIcon = styled.a`
  margin-left: 2rem; //for mobile
  font-size: ${(props) => props.theme.fontSizeMedium};
  height: 50px;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  color: ${(props) => props.theme.primaryBackground} !important;
  background-color: rgba(0, 0, 0, 0.05);
`;

const SidebarNav = styled.nav`
  background: ${(props) => props.theme.mainBrandColor};
  height: 100%;
  overflow: auto;
  transition: 500ms;
  @media only screen and (max-width: 768px) {
    left: ${({ sidebar }) => (sidebar ? '0' : '100%')};
    position: fixed;
    width: 220px;
  }
`;

const ImageWrapper = styled.figure`
  width: auto !important;
  padding: 1.2rem;
`;

const LogoutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  font-size: ${(props) => props.theme.fontSize};
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const sidebarData = [
  {
    id: 1,
    title: 'Jobs',
    path: '#',
    type: 'admin',
    icon: <AiIcons.AiOutlineMenu />,
    subNav: [
      {
        title: 'Add Jobs',
        path: '/job/create',
      },
      {
        title: 'View Open Jobs',
        path: '/jobs/open',
      },
      {
        title: 'View Completed Jobs',
        path: '/jobs/close',
      },
      {
        title: 'View Archived Jobs',
        path: '/jobs/archived',
      },
    ],
  },
  {
    id: 2,
    type: 'admin',
    title: 'Customers',
    path: '#',
    icon: <AiIcons.AiOutlineMenu />,
    subNav: [
      {
        title: 'Add New Customer',
        path: '/customer/create',
      },
      {
        title: 'View All',
        path: '/customers',
      },
    ],
  },
  {
    id: 3,
    type: 'admin',
    title: 'Users',
    path: '#',
    icon: <AiIcons.AiOutlineMenu />,
    subNav: [
      {
        title: 'Add Users',
        path: '/user/create',
      },
      {
        title: 'View All',
        path: '/users',
      },
    ],
  },
  {
    id: 4,
    title: 'Sub Contractors',
    type: 'subContractor', // Access contractor
    path: '#',
    icon: <AiIcons.AiOutlineMenu />,
    subNav: [
      {
        title: 'Assigned Jobs ',
        path: '/subContractor/dashboard',
      },
    ],
  },
  {
    id: 5,
    title: 'Engineer',
    type: 'engineer', // Access engineer
    path: '#',
    icon: <AiIcons.AiOutlineMenu />,
    subNav: [
      {
        title: 'Assigned Jobs ',
        path: '/engineer/dashboard',
      },
    ],
  },
  {
    id: 6,
    title: 'Jobs',
    path: '#',
    type: 'subAdmin',
    icon: <AiIcons.AiOutlineMenu />,
    subNav: [
      {
        title: 'Add Jobs',
        path: '/job/create',
      },
      {
        title: 'View Open Jobs',
        path: '/jobs/open',
      },
      // {
      //   title: 'View Revisit Jobs',
      //   path: '/jobs/revisit',
      // },
      {
        title: 'View Completed Jobs',
        path: '/jobs/close',
      },
      {
        title: 'View Archived Jobs',
        path: '/jobs/archived',
      },
    ],
  },
  {
    id: 7,
    type: 'subAdmin',
    title: 'Customers',
    path: '#',
    icon: <AiIcons.AiOutlineMenu />,
    subNav: [
      {
        title: 'Add New Customer',
        path: '/customer/create',
      },
      {
        title: 'View All',
        path: '/customers',
      },
    ],
  },
  {
    id: 8,
    type: 'subAdmin',
    title: 'Users',
    path: '#',
    icon: <AiIcons.AiOutlineMenu />,
    subNav: [
      {
        title: 'Add Users',
        path: '/user/create',
      },
      {
        title: 'View All',
        path: '/users',
      },
    ],
  },

];

const calendarItem = {
  id: 9,
  type: 'Admin',
  title: 'Calendar',
  path: '#',
  icon: <AiIcons.AiOutlineCalendar />,
  subNav: [
    {
      title: 'Calendar',
      path: '/calendar',
    },
  ],
 
}

const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const toggleLoggedIn = useStoreActions(
    (actions) => actions.isLoggedIn.toggle,
  );
  const userData = useStoreState((state) => state.user.data);

  return (
    <IconContext.Provider value={{ color: '#fff' }}>
      {/* <PushNotification userData={userData} /> */}
      <Nav
        className="navbar is-hidden-desktop"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <NavIcon
            type="button"
            className={isActive ? 'is-active navbar-burger' : 'navbar-burger'}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={() => setIsActive(!isActive)}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </NavIcon>
        </div>
        <div className={isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
          <div className="navbar-end">
            {sidebarData.map((item) => (
              <span key={item.id}>
                {item.type === userData.type && (
                  <SubMenu item={item} key={item.title} />
                )}
              </span>
            ))}
          </div>

          <LogoutWrapper onClick={() => toggleLoggedIn(false)} className="">
            <div className="has-text-white is-align-items-center is-flex">
              <BiIcons.BiLogOutCircle />
              <SidebarLabel className="has-text-white is-clickable">
                Logout
              </SidebarLabel>
            </div>
          </LogoutWrapper>
        </div>
      </Nav>
      <SidebarNav>
        <div>
          <ImageWrapper className="is-hidden-mobile">
            <img src={Logo} alt="logo" />
          </ImageWrapper>
          {sidebarData.map((item) => (
            <span key={item.id}>
              {item.type === userData.type && <SubMenu item={item} />}
            </span>
          ))}

          <SubMenu item={calendarItem} />
  
          <LogoutWrapper onClick={() => toggleLoggedIn(false)} className="">
            <div className="has-text-white is-align-items-center is-flex">
              <BiIcons.BiLogOutCircle />
              <SidebarLabel className="has-text-white is-clickable">
                Logout
              </SidebarLabel>
            </div>
          </LogoutWrapper>
        </div>
      </SidebarNav>
    </IconContext.Provider>
  );
};
export default Sidebar;
