import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

import theme from '../../utils/theme';

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 5,
    paddingVertical: 10,
    borderColor: theme.borderColor,
    width: '75%',
    borderWidth: 1,
    // alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontWeight: 'bold',
    fontSize: theme.fontSizeExtraSmall,
  },
});

const DetailItem = ({ title, value, marginTop, width }) => (
  <View
    style={{
      marginTop: marginTop || '0px',
      flexDirection: 'row',
    }}
    wrap={false}
  >
    <View
      style={[
        styles.wrapper,
        { backgroundColor: theme.lightShades, width: width || '35%' },
      ]}
    >
      <Text style={styles.text}>{title}</Text>
    </View>
    <View style={styles.wrapper}>
      <Text style={styles.text}>{value}</Text>
    </View>
  </View>
);

export default DetailItem;
