import { useState, useEffect } from 'react';
import { sortBy } from 'lodash';

const useSort = () => {
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleSorting = (filed) => {
    const sortedArray = sortBy(data, [filed]);
    setSortedData(sortedArray);
  };

  return [sortedData, { setData, handleSorting }];
};

export default useSort;
