import React, { useEffect } from 'react';

import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import Swal from 'sweetalert2';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import DashboardMenu from '../../components/global/DashboardMenu';
import { UserForm, UserPasswordForm } from '../../components/users';
import { Loading } from '../../components/elements';

const userQuery = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      telephone
      status
      type
      profile {
        fullName
      }
      account {
        registrationNumber
        vatNumber
        accountNumber
        accountEmail
        accountAddress
        accountTelephone
      }
    }
  }
`;

const updateUserMutation = gql`
  mutation updateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      id
    }
  }
`;

const setNewPasswordMutation = gql`
  mutation setNewPassword($input: SetNewPassword!) {
    setNewPassword(input: $input) {
      success
    }
  }
`;

const EditUserPage = ({ match, history }) => {
  const { id } = match.params;
  const { data, loading, error } = useQuery(userQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  const userData = data && data.user ? data.user : {};

  const [executeMutation, res] = useMutation(updateUserMutation);

  const [executeUpdatePassword, res1] = useMutation(setNewPasswordMutation);

  useEffect(() => {
    if (res.error || res1.error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.error.message || res1.error.message || '',
      });
    if (error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      });
  }, [res.error, error, res1.error]);

  const handleSubmit = async (formData) => {
    const resp = await executeMutation({
      variables: {
        id,
        input: formData,
      },
    });
    if (resp) {
      Swal.fire({
        icon: 'success',
        title: 'Update successful!',
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/users');
    }
  };

  const handleUpdatePassword = async (formData) => {
    const resp = await executeUpdatePassword({
      variables: {
        input: formData,
      },
    });
    if (resp) {
      Swal.fire({
        icon: 'success',
        title: 'Password update successful!',
        showConfirmButton: false,
        timer: 1500,
      });
      history.push('/users');
    }
  };
  return (
    <Layout>
      <Seo
        title="Update Contractor"
        description="Update Contractor information"
      />
      <DashboardMenu heading="Update Contractor">
        {loading ? (
          <Loading />
        ) : (
          <>
            <UserForm
              enableReinitialize
              initialValues={userData}
              onSubmit={handleSubmit}
              hidePassword
            />
            <hr />
            <UserPasswordForm
              onSubmit={(val) =>
                handleUpdatePassword({
                  email: userData.email,
                  ...val,
                })
              }
            />
          </>
        )}
      </DashboardMenu>
    </Layout>
  );
};

export default EditUserPage;
