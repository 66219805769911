import * as Sentry from '@sentry/browser';

import config from './config';

const dsn =
  'https://dc2c44283ce2484faca7514d3ea66c01@o211599.ingest.sentry.io/5828022';

if (!config.debug) {
  Sentry.init({
    dsn,
  });
}

export default Sentry;
