import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { GlobalStyle } from './theme';

import Home from '../pages/Home';
import Error404 from '../pages/404';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
// import Test from '../pages/Test';
import Jobs from '../pages/admin/Jobs';
import CreateJob from '../pages/admin/CreateJob';
import EditJob from '../pages/admin/EditJob';
import Customers from '../pages/admin/Customers';
import CreateCustomer from '../pages/admin/CreateCustomer';
import EditCustomer from '../pages/admin/EditCustomer';
import Users from '../pages/admin/Users';
import CreateUser from '../pages/admin/CreateUser';
import EditUser from '../pages/admin/EditUser';
import JobPdfView from '../pages/JobPdfView';
import ContractorDashboard from '../pages/contractor/Dashboard';
import JobSurvey from '../pages/contractor/JobSurvey';
import EngineerDashboard from '../pages/engineer/Dashboard';
import EngineerEditJob from '../pages/engineer/EditJob';
import CalendarPage from '../pages/Calendar';
// import Survey from '../pages/contractor/Survey';

const PrivateRoute = ({ component: Component, access, ...rest }) => {
  const isLoggedIn = useStoreState((state) => state.isLoggedIn.value);
  const user = useStoreState((state) => state.user.data);

  let accessUserType = access;

  if (user.type === 'subAdmin') {
    accessUserType = 'subAdmin';
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          return <Redirect to="/login" />;
        }
        if (accessUserType !== user.type && accessUserType !== "calendar") {
          return <Redirect to="/not-allowed" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const Routes = () => {
  const userData = useStoreState((state) => state.user.data);

  const userType = userData.type;

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute
            exact
            path="/jobs/:status"
            component={Jobs}
            access="admin"
          />
          <PrivateRoute
            exact
            path="/job/create"
            component={CreateJob}
            access="admin"
          />
          <PrivateRoute
            exact
            path="/job/:id"
            component={EditJob}
            access="admin"
          />
          <PrivateRoute
            exact
            path="/customers"
            component={Customers}
            access="admin"
          />
          <PrivateRoute
            exact
            path="/customer/create"
            component={CreateCustomer}
            access="admin"
          />
          <PrivateRoute
            exact
            path="/customer/:id"
            component={EditCustomer}
            access="admin"
          />
          <PrivateRoute exact path="/users" component={Users} access="admin" />
          <PrivateRoute
            exact
            path="/user/create"
            component={CreateUser}
            access="admin"
          />
          <PrivateRoute
            exact
            path="/calendar"
            component={CalendarPage}
            access="calendar"
          />
          <PrivateRoute
            exact
            path="/subContractor/dashboard"
            access="subContractor"
            component={ContractorDashboard}
          />
          <PrivateRoute
            exact
            path="/subContractor/:id"
            component={EditUser}
            access="admin"
          />
          <PrivateRoute
            exact
            path="/subContractor/job/:id"
            access="subContractor"
            component={JobSurvey}
          />
          <PrivateRoute
            exact
            path="/engineer/dashboard"
            access="engineer"
            component={EngineerDashboard}
          />
          <PrivateRoute
            exact
            path="/engineer/job/:id"
            access="engineer"
            component={EngineerEditJob}
          />
          <PrivateRoute
            exact
            access={userType}
            path={`/${userType}/job-pdf/:id`}
            component={JobPdfView}
          />

          {/* <Route exact path="/test" component={Test} /> */}
          <Route component={Error404} />
        </Switch>
      </BrowserRouter>
      <GlobalStyle />
    </>
  );
};

export default Routes;
