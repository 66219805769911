import React from 'react';
import moment from 'moment';
import DetailItem from './DetailItem';

import Images from './Images';

const CompletedDetails = ({ jobData }) => {
  const personalInfo = [
    {
      key: 1,
      title: 'Name',
      value: jobData && jobData.signedBy ? jobData.signedBy : '',
    },
    {
      key: 2,
      title: 'Signature',
      value: '',
    },
    {
      key: 3,
      title: 'Finish time',
      value:
        jobData && jobData.dateSigned
          ? moment(jobData.dateSigned).format('h:mm A')
          : '',
    },
    {
      key: 4,
      title: 'Return visit required',
      value: jobData && jobData.hasRevisit ? 'Yes' : 'No',
    },
    {
      key: 5,
      title: 'Location with in the building',
      value:
        jobData && jobData.locationInBuilding ? jobData.locationInBuilding : '',
    },
    {
      key: 6,
      title: 'Works completed this visit',
      value: jobData && jobData.worksCompleted ? jobData.worksCompleted : '',
    },
  ];

  return (
    <>
      {personalInfo.map((item, index) => {
        if (item.title === 'Signature') {
          return (
            <Images
              key={item.key}
              title="Signature"
              images={[jobData.files && jobData.files.signature]}
            />
          );
        }
        return (
          <DetailItem
            key={item.key}
            title={item.title}
            value={item.value}
            marginTop={index === 0 || index === 3 ? '20px' : '0px'}
          />
        );
      })}
    </>
  );
};

export default CompletedDetails;
