import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';

import { TableHead, Tag } from '../elements';

const tableHeadData = [
  { id: 1, name: 'Name' },
  { id: 2, name: 'Email' },
  // { id: 3, name: 'Address' },
  // { id: 4, name: 'Telephone' },
  { id: 3, name: 'Type' },
  { id: 4, name: 'Status' },
  { id: 5, name: 'Actions' },
];

const UsersTable = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <div className="table-container">
      <table className="table is-fullwidth">
        <TableHead tableHeadData={tableHeadData} />
        <tbody>
          {data.map((item) => {
            if (!item) {
              return null;
            }

            return (
              <tr key={item.id}>
                <td>{item.profile ? item.profile.fullName : ''}</td>
                <td>{item.email}</td>
                {/* <td>{item.account ? item.account.accountAddress : ''}</td> */}
                {/* <td>{item.telephone}</td> */}
                <td>{capitalize(item.type)}</td>
                <td>
                  <Tag status={item.status} />
                </td>
                <td>
                  <Link
                    to={`/subContractor/${item.id}`}
                    className="button is-primary">
                    <span className="icon is-small">
                      <i className="fas fa-eye" />
                    </span>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
