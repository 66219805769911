import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as RiIcons from 'react-icons/ri';
import { findIndex } from 'lodash';

const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: ${(props) => props.theme.fontSize};
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  height: 60px;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize};
  background-color: ${(props) =>
    props.active
      ? `${props.theme.secondaryColor}50`
      : `${props.theme.mainBrandColor}`};
`;

const SubMenu = ({ item }) => {
  const location = useLocation();
  const [subNav, setSubNav] = useState(true);
  // console.log('subNav', subNav);

  useEffect(() => {
    const currentPath = location.pathname;
    const index = findIndex(item.subNav, { path: currentPath });
    // console.log('index', index, currentPath, item.subNav);
    setSubNav(index !== -1);
  }, []);

  return (
    <>
      <SidebarLink to={item.path} onClick={() => setSubNav(!subNav)}>
        <div className="has-text-white is-align-items-center is-flex">
          {item.icon}
          <SidebarLabel className="has-text-white">{item.title}</SidebarLabel>
        </div>
        {item.subNav && subNav && (
          <RiIcons.RiArrowDownSFill className="hello" />
        )}
        {item.subNav && !subNav && <RiIcons.RiArrowRightSFill />}
      </SidebarLink>
      {subNav &&
        item.subNav.map((item1) => (
          <DropdownLink
            className="has-text-white child pl-6"
            active={useRouteMatch(item1.path)}
            to={item1.path}
            key={item1.title}
          >
            <SidebarLabel>{item1.title}</SidebarLabel>
          </DropdownLink>
        ))}
    </>
  );
};

export default SubMenu;
