import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import moment from 'moment';

import theme from '../../utils/theme';

const styles = StyleSheet.create({
  Section: {
    marginTop: 28,
    // backgroundColor: 'red',
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  wrapper: {
    paddingHorizontal: 5,
    paddingVertical: 12,
    borderColor: theme.borderColor,
    width: '25%',
    borderWidth: 1,
    // alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontWeight: 'bold',
    fontSize: theme.fontSizeExtraSmall,
  },
});

const DetailItem = ({ data }) => (
  <>
    <View style={[styles.wrapper, { backgroundColor: theme.lightShades }]}>
      <Text style={styles.text}>{data.title}</Text>
    </View>
    <View style={styles.wrapper}>
      <Text style={styles.text}>{data.value}</Text>
    </View>
  </>
);

const WorkSheetDetails = ({ jobData }) => {
  const detailsArray = [
    {
      key: 1,
      title: 'Engineers',
      value:
        jobData && jobData.contractor
          ? jobData.contractor.profile.fullName
          : '',
    },
    {
      key: 2,
      title: 'Date',
      value:
        jobData && jobData.startDate
          ? moment(jobData.startDate).format('DD-MM-YYYY')
          : '',
    },
    {
      key: 3,
      title: 'Customer',
      value: jobData && jobData.customer ? jobData.customer.name : '',
    },
    {
      key: 4,
      title: 'Customer Order No',
      value:
        jobData && jobData.customerReference ? jobData.customerReference : '',
    },
    {
      key: 5,
      title: 'Site',
      value: jobData && jobData.location ? jobData.location.name : '',
    },
    {
      key: 6,
      title: 'Start Time',
      value:
        jobData && jobData.startDate
          ? moment(jobData.startDate).format('h:mm A')
          : '',
    },
  ];

  return (
    <View style={styles.Section} wrap={false}>
      <View style={styles.container}>
        {detailsArray.map((item) => (
          <DetailItem key={item.key} data={item} />
        ))}
      </View>
    </View>
  );
};

export default WorkSheetDetails;
