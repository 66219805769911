/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.fontDark};
  border-bottom: 2px solid ${(props) => props.theme.borderColor};
  font-size: ${(props) =>
    props.small ? props.theme.fontSizeLarge : props.theme.fontSizeExtraLarge};
`;

const StyledButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  /* bottom: 2rem; */
`;

const Line = styled.div`
  height: 3px;
  width: 80px;
  background-color: ${(props) => props.theme.mainBrandColor};
  margin: 0.5rem auto 0.5rem auto;
`;

const Heading = ({
  children,
  small,
  centered,
  hasHideButton,
  onClickHideJobs,
  handleExpotCSV,
  jobsToExport,
  ...otherProps
}) => (
  <Container>
    <Title
      small={small}
      className={`title has-text-black pb-3 has-text-weight-semibold ${
        centered ? 'has-text-centered' : ''
      }  `}
      {...otherProps}
    >
      {children}
    </Title>
    {hasHideButton && (
      <>
        <StyledButton
          className="button is-primary"
          type="button"
          onClick={onClickHideJobs}
        >
          Hide Archive jobs {`>`} 30 days
        </StyledButton>
        {/* <button onClick={handleExpotCSV}  className="button is-primary" type='button'>  </button> */}
        <CSVLink
          className="button is-primary"
          asyncOnClick
          data={jobsToExport}
          onClick={handleExpotCSV}
          filename="exportedJobs.csv"
        >
          Export to CSV
        </CSVLink>
        
      </>
    )}

    {centered && <Line />}
  </Container>
);

export default Heading;
