import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as yup from 'yup';
// import { getGeocode } from 'use-places-autocomplete';
// import PlacesAutocomplete from 'react-places-autocomplete';
import styled from 'styled-components';

import { InputGroup, Button, TextArea } from '../elements';

const Form = styled.div`
  .sc-gtsrHT {
    border-color: ${(props) =>
      props.error ? props.theme.dangerColor : props.theme.lightAccent};
  }
`;

// const normalizeAddress = (data, locationName) => {
//   const response = {
//     name: locationName,
//     addressLine1: '',
//     city: '',
//     state: '',
//     postcode: '',
//     country: '',
//     location: {
//       coordinates: [],
//     },
//     formattedAddress: '',
//   };
//   if (!data[0]) {
//     return response;
//   }
//   const matchedItem = data[0];

//   if (matchedItem.geometry && matchedItem.geometry.location) {
//     // response.location.type = matchedItem.geometry.location_type;
//     response.location.coordinates = [
//       matchedItem.geometry.location.lat(),
//       matchedItem.geometry.location.lng(),
//     ];
//   }
//   let addressLine1 = '';
//   response.formattedAddress = matchedItem.formatted_address;

//   matchedItem.address_components.forEach((item) => {
//     const key = item.types[0];

//     switch (key) {
//       case 'street_number':
//         addressLine1 = item.long_name;
//         break;
//       case 'route':
//         addressLine1 = `${addressLine1}, ${item.long_name}`;
//         break;
//       case 'administrative_area_level_1':
//         response.state = item.long_name;
//         break;
//       case 'locality':
//         response.city = item.long_name;
//         break;
//       case 'country':
//         response.country = item.long_name;
//         break;
//       case 'postal_code':
//         response.postcode = item.long_name;
//         break;
//       case 'administrative_area_level_2':
//         response.state = item.long_name;
//         break;
//       default:
//     }
//   });
//   response.addressLine1 = addressLine1;

//   return response;
// };

// const searchOptions = {
//   // eslint-disable-next-line no-undef
//   location: new google.maps.LatLng(51.509865, -0.118092),
//   radius: 2000,
// };

const AddressForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = props;

  // const removeDuplicate = (str) => {
  //   const set = new Set();
  //   const words = str.split(' ');
  //   words.forEach((ele) => {
  //     set.add(ele);
  //   });
  //   const value = [...set].join(' ');
  //   console.log([...set].join(' '));

  //   return value;
  // };

  // console.log('formattedAddress', values.address.formattedAddress);

  return (
    <Form error={errors.address}>
      <InputGroup
        placeholder="John Doe"
        label="Name"
        name="name"
        type="text"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.name && touched.name ? errors.name : undefined}
      />
      <InputGroup
        placeholder="12345"
        label="Store Number"
        name="number"
        type="text"
        value={values.number}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.number && touched.number ? errors.number : undefined}
      />
      {/* <InputGroup
        placeholder="Location.."
        label="Location"
        name="number"
        type="text"
        value={values.number}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.number && touched.number ? errors.number : undefined}
      /> */}
      <div className="mb-4">
        <InputGroup
          label="Site"
          onChange={(e) =>
            setFieldValue('address.formattedAddress', e.target.value)
          }
          isMedium
          value={values.address.formattedAddress}
        />
        {/* <PlacesAutocomplete
          name="address.formattedAddress"
          value={removeDuplicate(
            `${values.address.name ? `${values.address.name}` : ''}${
              values.address.formattedAddress
            }`,
          )}
          onChange={(e) => {
            setFieldValue('address.name', e);
            if (values.address.formattedAddress) {
              setFieldValue('address.formattedAddress', e);
            }
          }}
          searchOptions={searchOptions}
          onSelect={(e) => {
            if (e) {
              const nameArr = e.split(',');
              const locationName = nameArr[0];
              getGeocode({ address: e }).then((results) => {
                const formattedData = normalizeAddress(results, locationName);
                setFieldValue('address', {
                  ...formattedData,
                });
              });
            }
          }}
          onBlur={handleBlur}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="autocomplete-wrapper">
              <InputGroup
                label="Site"
                onChange={(e) => setFieldValue('address.formattedAddress', e)}
                isMedium
                {...getInputProps({
                  placeholder: 'Add new site...',
                })}
              />
              <div
                className={`autocomplete-dropdown-container ${
                  suggestions.length > 0 ? '' : 'hidden'
                }`}
              >
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      key={suggestion.placeId}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete> */}
        {errors.address && (
          <p className="help is-danger mb-3">Location is required!</p>
        )}
      </div>
      {/* <GoogleMap address={values.address} /> */}
      <TextArea
        placeholder="Internal Notes"
        label="Internal Notes"
        name="internalNotes"
        type="text"
        value={values.internalNotes}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={
          errors.internalNotes && touched.internalNotes
            ? errors.internalNotes
            : undefined
        }
      />
      <div className="mt-5">
        <Button primary onClick={handleSubmit}>
          <span className="has-text-weight-bold">Submit</span>
        </Button>
      </div>
    </Form>
  );
};

AddressForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    name: initialValues ? initialValues.name : '',
    number: initialValues ? initialValues.number : '',
    internalNotes: initialValues ? initialValues.internalNotes : '',
    address: {
      addressLine1:
        initialValues &&
        initialValues.address &&
        initialValues.address.addressLine1
          ? initialValues.address.addressLine1
          : '',
      addressLine2:
        initialValues &&
        initialValues.address &&
        initialValues.address.addressLine2
          ? initialValues.address.addressLine2
          : '',
      city:
        initialValues && initialValues.address && initialValues.address.city
          ? initialValues.address.city
          : '',
      state:
        initialValues && initialValues.address && initialValues.address.state
          ? initialValues.address.state
          : '',
      postcode:
        initialValues && initialValues.address && initialValues.address.postcode
          ? initialValues.address.postcode
          : '',
      country:
        initialValues && initialValues.address && initialValues.address.country
          ? initialValues.address.country
          : '',
      formattedAddress:
        initialValues &&
        initialValues.address &&
        initialValues.address.formattedAddress
          ? initialValues.address.formattedAddress
          : '',
      location: {
        coordinates:
          initialValues &&
          initialValues.address &&
          initialValues.address.location &&
          initialValues.address.location.coordinates
            ? initialValues.address.location.coordinates
            : 0,
      },
    },
  }),
  validationSchema: yup.object().shape({
    name: yup.string().required('Name is required!'),
    number: yup.string().required('Number is required!'),
    address: yup.object().shape({
      // addressLine1: yup.string().required(),
      // city: yup.string(),
      formattedAddress: yup.string().required(),
    }),
    internalNotes: yup.string(),
  }),

  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    // console.log('handle submit', values, props);
    props.onSubmit(values);
    resetForm();
    setSubmitting(false);
  },
  displayName: 'AddressForm', // helps with React DevTools
})(AddressForm);
