import React, { useState, useEffect } from 'react';
import randomstring from 'randomstring';
import PropTypes from 'prop-types';
import { withFormik, FieldArray } from 'formik';
import * as yup from 'yup';
import { remove } from 'lodash';

import { InputGroup, Button, Select, Heading } from '../elements';
import { AddLocationModal } from '../modals';
import useSearchFilter from '../../hooks/useSearchFilter';

import Searchbar from '../global/Searchbar';

const groupList = [
  { value: 'active', label: 'Active' },
  { value: 'notActive', label: 'Not Active' },
  { value: 'archived', label: 'Archived' },
];

const LocationItem = ({ val, handleDelete, handleEdit }) => (
  <tr>
    <td> {val.name}</td>
    <td> {val.number}</td>
    <td>
      <button
        type="button"
        className="button is-small is-success"
        onClick={handleEdit}
      >
        <span>Edit</span>
        <span className="icon is-small">
          <i className="fas fa-times" />
        </span>
      </button>
    </td>
    <td>
      <button
        type="button"
        onClick={handleDelete}
        className="button is-small is-danger is-outlined"
      >
        <span>Delete</span>
        <span className="icon is-small">
          <i className="fas fa-times" />
        </span>
      </button>
    </td>
  </tr>
);

const CustomerForm = (props) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = props;
  const [filteredData, { onSearch, setAllData }] = useSearchFilter(['name']);
  const [locationVariant, setLocationVariant] = useState(false);
  const [openModel, setOpenModel] = useState(false);

  useEffect(() => {
    if (values.locations) {
      setAllData(values.locations);
    }
  }, [values]);

  const handleDeleteSites = (obj) => {
    // eslint-disable-next-line prefer-const
    let sites = [...values.locations];
    remove(sites, (n) => n.id === obj.id);
    setFieldValue('locations', sites);
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup
        label="Company Name:"
        name="name"
        type="text"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.name && touched.name ? errors.name : undefined}
      />
      <InputGroup
        label="Account Email"
        name="accountsEmail"
        type="email"
        value={values.accountsEmail}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={
          errors.accountsEmail && touched.accountsEmail
            ? errors.accountsEmail
            : undefined
        }
      />

      <div className="box box-wrapper">
        <Heading small>Add Sites</Heading>

        <div className="columns">
          <div className="column is-2">
            <button
              onClick={() => setOpenModel(!openModel)}
              className="button is-small is-primary mb-4"
              type="button"
            >
              Add Site
            </button>
          </div>
          <div className="column is-10">
            <div className="field">
              <Searchbar hideTitle onChange={onSearch} />
            </div>
          </div>
        </div>

        <FieldArray
          name="locations"
          render={(arrayHelpers) => (
            <>
              <AddLocationModal
                isActive={
                  typeof locationVariant === 'number' && locationVariant >= 0
                }
                onClose={() => setLocationVariant(false)}
                handleBlur={handleBlur}
                values={filteredData[locationVariant]}
                onSubmit={(data) => {
                  arrayHelpers.remove(locationVariant);
                  arrayHelpers.push(data);
                  setLocationVariant(false);
                }}
              />
              <div className="table-container">
                <table className="table is-fullwidth">
                  <tbody>
                    {filteredData.map((val, index) => (
                      <LocationItem
                        key={randomstring.generate({
                          length: 4,
                        })}
                        val={val}
                        handleEdit={() => setLocationVariant(index)}
                        handleDelete={() => handleDeleteSites(val)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        />
        <FieldArray
          name="locations"
          render={(arrayHelpers) => (
            <AddLocationModal
              isActive={openModel}
              onClose={() => setOpenModel(!openModel)}
              onSubmit={(data) => {
                arrayHelpers.push(data);
                setOpenModel(!openModel);
              }}
            />
          )}
        />
      </div>

      <Select
        placeholder="Select..."
        label="Status"
        name="status"
        options={groupList}
        type="text"
        value={values.status}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.status && touched.status ? errors.status : undefined}
      />
      <div className="mb-3 mt-4">
        <Button primary type="submit" loading={isSubmitting}>
          <span className="has-text-weight-bold">Submit</span>
        </Button>
      </div>
    </form>
  );
};

CustomerForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues: ({ initialValues, locations }) => ({
    name: (initialValues && initialValues.name) || '',
    accountsEmail: (initialValues && initialValues.accountsEmail) || '',
    status: (initialValues && initialValues.status) || 'active',
    locations: locations || [],
  }),

  validationSchema: yup.object().shape({
    name: yup.string().required('Company name is required!'),
    accountsEmail: yup
      .string()
      .email('Invalid email address')
      .required('Jobs email is required!'),
  }),

  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    props.onSubmit(values).then(() => {
      setSubmitting(false);
      resetForm();
    });
  },
  displayName: 'CustomerForm', // helps with React DevTools
})(CustomerForm);
