import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { DropDown } from '../elements';

const allUsersQuery = gql`
  query allUsers($status: String) {
    allUsers(status: $status) {
      id
      type
      status
      profile {
        fullName
      }
    }
  }
`;

const ContractorsDropDown = ({
  errors,
  value,
  onBlur,
  onChange,
  label,
  name,
}) => {
  const { data } = useQuery(allUsersQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      status: 'active',
    },
  });

  const allContractors = data && data.allUsers ? data.allUsers : [];

  return (
    <DropDown
      label={label}
      name={name}
      defaultValue={value}
      onBlur={onBlur}
      onChange={onChange}
      options={
        allContractors
          ? allContractors.map((item) => ({
              value: item.id,
              label:
                item.profile && item.profile.fullName
                  ? item.profile.fullName
                  : '',
            }))
          : []
      }
      errors={errors}
    />
  );
};

export default ContractorsDropDown;
