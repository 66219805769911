import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import Swal from 'sweetalert2';
import Seo from '../../components/Seo';

import Layout from '../../components/Layout';
import DashboardMenu from '../../components/global/DashboardMenu';
import { UserForm } from '../../components/users';

const insertUserMutation = gql`
  mutation insertUser($input: UserInput!) {
    insertUser(input: $input) {
      id
      email
      telephone
      status
      profile {
        fullName
      }
      account {
        registrationNumber
        vatNumber
        accountNumber
        accountEmail
        accountAddress
        accountTelephone
      }
      profile {
        fullName
      }
    }
  }
`;

const CreateUser = ({ history }) => {
  const [executeMutation, res] = useMutation(insertUserMutation);
  useEffect(() => {
    if (res.error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.error.message,
      });
  }, [res.error]);

  if (res.data && res.data.insertUser) {
    history.push(`/users`);
  }

  const handleSubmit = async (formData) => {
    const resp = await executeMutation({
      variables: { input: {...formData, telephone: `${formData.telephone}`} },
    });
    if (resp) {
      Swal.fire({
        icon: 'success',
        title: 'User added successfully',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <Layout>
      <Seo title="Add User" description="Add User information" />
      <DashboardMenu heading="Add User">
        <UserForm
          initialValues={{}}
          hidePassword={false}
          onSubmit={handleSubmit}
        />
      </DashboardMenu>
    </Layout>
  );
};

export default CreateUser;
