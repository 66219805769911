import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik, FieldArray } from 'formik';
import * as yup from 'yup';
import randomstring from 'randomstring';

import { AddSurveyModal } from '../modals';

import {
  InputGroup,
  RadioSelect,
  TextArea,
  SignaturePad,
  DatePicker,
  Heading,
  Button,
  FileGroup,
} from '../elements';
import SurveyItem from './SurveyItem';
import OfflineImageUploading from '../global/OfflineImageUploading';

const Container = styled.section`
  .checkbox {
    width: 30px;
    height: 50px;
  }
`;

const BooleanOptions = [{ label: 'Yes' }, { label: 'No' }];

const WorkingWith = [
  { label: 'Another contractor' },
  { label: 'Colleague' },
  { label: 'No-one' },
];

const riskAssessments = [
  {
    title:
      'I have read and understand the generic risk assessments and method statements.',
    value: '',
  },
  {
    title:
      'I have carried out a site-specific risk assessment and can confirm that I am working in a safe environment.',
    value: '',
  },
  {
    title: 'Is there any risks to customers, visitors or the public?',
    value: '',
  },
  {
    title:
      'Is there a suspicion of asbestos? If yes, please answer the question below to confirm that you have received asbestos awareness training and can continue to work safely.',
    value: '',
  },
  {
    title:
      'Yes, I have received asbestos awareness training and continue to work safely.',
    value: '',
  },
  {
    title: 'Who will be working with you?',
    value: '',
  },
];

const JobSurveyForm = (props) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = props;

  const [active, setActive] = useState(false);

  const handleJobUpdate = () => {
    handleSubmit();
  };

  return (
    <Container>
      <OfflineImageUploading />
      <form>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field-body mb-5">
              <DatePicker
                label="Date received"
                value={values.startDate}
                onChange={(date) => setFieldValue('startDate', date)}
                dateFormat="MMMM d, yyyy"
              />
              <DatePicker
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                label="Time received"
                value={values.startDate}
                onChange={(date) => setFieldValue('startDate', date)}
                dateFormat="h:mm aa"
              />
            </div>
          </div>
        </div>
        <div className="mt-6">
          <Heading>Risk Assessment</Heading>
        </div>
        <div>
          {values.riskAssessments.slice(0, 3).map((item, index) => (
            <RadioSelect
              key={randomstring.generate({
                length: 4,
              })}
              label={item.title}
              value={item.value}
              onChange={(val) =>
                setFieldValue(`riskAssessments[${index}].value`, val)
              }
              options={BooleanOptions}
            />
          ))}
          <div className="mt-6">
            <TextArea
              label="Risk Assessment Notes"
              placeholder="If yes, please give details of the risk and how you intend to minimise the risk to allow you to work safely."
              name="riskAssessmentNotes"
              type="text"
              value={values.riskAssessmentNotes}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                errors.riskAssessmentNotes && touched.riskAssessmentNotes
                  ? errors.riskAssessmentNotes
                  : undefined
              }
            />
          </div>
          {values.riskAssessments.slice(3, 5).map((item, index) => (
            <RadioSelect
              key={randomstring.generate({
                length: 4,
              })}
              label={item.title}
              value={item.value}
              onChange={(val) =>
                setFieldValue(`riskAssessments[${index + 3}].value`, val)
              }
              options={BooleanOptions}
            />
          ))}
          {values.riskAssessments.slice(5, 6).map((item, index) => (
            <RadioSelect
              key={randomstring.generate({
                length: 4,
              })}
              size="is-one-third"
              label={item.title}
              value={item.value}
              onChange={(val) =>
                setFieldValue(`riskAssessments[${index + 5}].value`, val)
              }
              options={WorkingWith}
            />
          ))}
          <FileGroup
            label="Before Picture"
            value={values.beforePicture}
            placeholder="Before Picture"
            onChange={(val) =>
              setFieldValue('beforePicture', [...values.beforePicture, val])
            }
            errors={
              errors.beforePicture && touched.beforePicture
                ? errors.beforePicture
                : undefined
            }
          />
          <InputGroup
            label="Location"
            name="location"
            type="text"
            placeholder="Location"
            value={values.location}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.location && touched.location ? errors.location : undefined
            }
          />
          <InputGroup
            label="Location within the building"
            name="locationInBuilding"
            type="text"
            placeholder="Location within the building"
            value={values.locationInBuilding}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="mt-4">
            <TextArea
              label="Works completed this visit"
              placeholder="Add jobs completed"
              name="worksCompleted"
              type="text"
              value={values.worksCompleted}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                errors.worksCompleted && touched.worksCompleted
                  ? errors.worksCompleted
                  : undefined
              }
            />
          </div>
          <button
            type="button"
            className="button is-primary mt-4 mb-4"
            onClick={() => setActive(true)}
          >
            Add Survey
          </button>
          <FieldArray
            name="surveys"
            render={(arrayHelpers) => (
              <>
                <div className="table-container">
                  <table className="table is-fullwidth">
                    <tbody>
                      {values.surveys.map((val, index) => (
                        <SurveyItem
                          key={randomstring.generate({
                            length: 4,
                          })}
                          val={val}
                          onSubmit={(data) => {
                            arrayHelpers.remove(index);
                            arrayHelpers.push(data);
                          }}
                          handleDelete={() => arrayHelpers.remove(index)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          />
          {active && (
            <FieldArray
              name="surveys"
              render={(arrayHelpers) => (
                <AddSurveyModal
                  isActive={active}
                  onClose={() => setActive(false)}
                  onSubmit={(data) => {
                    arrayHelpers.push(data);
                    setActive(false);
                  }}
                />
              )}
            />
          )}
          <div className="mt-6">
            <RadioSelect
              size="is-one-third"
              label="Is a return visit required?"
              value={values.hasRevisit ? 'Yes' : 'No'}
              onChange={(val) => setFieldValue('hasRevisit', val === 'Yes')}
              options={BooleanOptions}
            />
          </div>
          {values.hasRevisit && (
            <div className="mt-6">
              <TextArea
                label="Revisit Notes"
                placeholder="If yes, please give details"
                name="revisitNotes"
                type="text"
                value={values.revisitNotes}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          )}

          <div className="mt-5 mb-5">
            <p className="subtitle is-6">
              I can confirm that I am satisfied with the above works that have
              been undertaken.
            </p>
          </div>
          <InputGroup
            label="Name"
            name="signedBy"
            type="text"
            placeholder="name"
            value={values.signedBy}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.signedBy && touched.signedBy ? errors.signedBy : undefined
            }
          />
          <div className="field-body mt-3">
            <SignaturePad
              label="Signature"
              value={values.signature}
              handleChange={(val) => {
                setFieldValue('signature', val);
                setFieldValue('dateSigned', new Date());
              }}
            />
          </div>
          <div className="field-body mt-3 mb-3">
            <FileGroup
              label="After Picture"
              value={values.afterPicture}
              placeholder="After Picture"
              onChange={(val) =>
                setFieldValue('afterPicture', [...values.afterPicture, val])
              }
              errors={
                errors.afterPicture && touched.afterPicture
                  ? errors.afterPicture
                  : undefined
              }
            />
          </div>
          <div className="field-body mb-5">
            <DatePicker
              label="Date signed"
              value={values.dateSigned}
              // onChange={(date) => setFieldValue('dateSigned', date)}
              dateFormat="MMMM d, yyyy"
            />
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              label="Time signed"
              value={values.dateSigned}
              // onChange={(date) => setFieldValue('dateSigned', date)}
              dateFormat="h:mm aa"
            />
          </div>
          <div className="">
            <Button
              type="button"
              primary
              onClick={handleJobUpdate}
              loading={isSubmitting}
            >
              <span className="has-text-weight-bold">Submit</span>
            </Button>
          </div>
        </div>
      </form>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    startDate: initialValues ? new Date(initialValues.startDate) : new Date(),
    dueDate: initialValues ? new Date(initialValues.dueDate) : new Date(),
    riskAssessments:
      initialValues &&
      initialValues.riskAssessments &&
      initialValues.riskAssessments.length >= 6
        ? initialValues.riskAssessments
        : riskAssessments,
    signedBy:
      initialValues && initialValues.signedBy ? initialValues.signedBy : '',
    location:
      initialValues &&
      initialValues.location &&
      initialValues.location.formattedAddress
        ? initialValues.location.formattedAddress
        : '',
    riskAssessmentNotes:
      initialValues && initialValues.riskAssessmentNotes
        ? initialValues.riskAssessmentNotes
        : '',
    worksCompleted:
      initialValues && initialValues.worksCompleted
        ? initialValues.worksCompleted
        : '',
    surveys: initialValues.surveys,
    dateSigned:
      initialValues && initialValues.dateSigned
        ? new Date(initialValues.dateSigned)
        : '',
    beforePicture:
      initialValues && initialValues.files && initialValues.files.beforePicture
        ? initialValues.files.beforePicture
        : [],
    afterPicture:
      initialValues && initialValues.files && initialValues.files.afterPicture
        ? initialValues.files.afterPicture
        : [],
    signature:
      initialValues && initialValues.files && initialValues.files.signature
        ? initialValues.files.signature
        : '',
    status: initialValues && initialValues.status ? initialValues.status : '',
    locationInBuilding:
      initialValues && initialValues.locationInBuilding
        ? initialValues.locationInBuilding
        : '',
    hasRevisit:
      initialValues && initialValues.hasRevisit
        ? initialValues.hasRevisit
        : false,
    revisitNotes:
      initialValues && initialValues.revisitNotes
        ? initialValues.revisitNotes
        : '',
  }),
  validationSchema: yup.object().shape({
    // TODO:add validations
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    const riskAssessmentsValues = values.riskAssessments.map((item) => ({
      title: item.title,
      value: item.value,
    }));

    const surveys = values.surveys.map((item) => ({
      mensToComplete: item.mensToComplete,
      hoursToComplete: item.hoursToComplete,
      outOfHours: item.hoursToComplete,
      details: item.details,
      surveyPicture: item.surveyPicture,
    }));

    props
      .onSubmit({
        riskAssessments: riskAssessmentsValues,
        riskAssessmentNotes: values.riskAssessmentNotes,
        worksCompleted: values.worksCompleted,
        signedBy: values.signedBy,
        dateSigned: values.dateSigned,
        status: values.dateSigned && values.signature ? 'close' : values.status,
        locationInBuilding: values.locationInBuilding,
        hasRevisit: values.hasRevisit,
        revisitNotes: values.revisitNotes,
        surveys,
        files: {
          beforePicture: values.beforePicture,
          afterPicture: values.afterPicture,
          signature: values.signature,
        },
      })
      .then(() => {
        setSubmitting(false);
      });
  },
  displayName: 'JobSurveyForm', // helps with React DevTools
})(JobSurveyForm);
